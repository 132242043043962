import React, { useState, useEffect, version } from 'react';
import Profile from './Profile';
import styles from './styles';
import { Button } from '@material-ui/core';
import Refer from './Refer';
import { useNotify, Loading } from 'react-admin';

import TerminalCredentials from './TerminalCredentials';
import { useSelector } from 'react-redux';
import { getTerminalsCredList } from '@/actions';

export default () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();

  const [terminalsListRes, setTerminalsListRes] = useState([]);
  const [terminalsCredRes, setTerminalsCredRes] = useState([]);
  const cls = styles();
  const enable_login_credentials = useSelector(
    (state) => state.reducer.user?.metas?.enable_login_credentials?.value
  );

  const getTerminalsListCredFanc = () => {
    setLoading(true);
    getTerminalsCredList()
      .then((res) => {
        setTerminalsListRes(res.data?.default_list);
        setTerminalsCredRes(res.data?.user_credentials);
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  useEffect(() => {
    getTerminalsListCredFanc();
  }, []);

  // console.log('enable_login_credentials', enable_login_credentials);
  const appVersion = 'v1.9.1';
  const profileTabItems = [
    {
      title: 'Profile',
      content: <Profile />,
    },
    {
      title: 'Referral',
      content: <Refer setSelectedTab={setSelectedTab} />,
    },
    {
      title: 'Terminal Credentials',
      content: (
        <TerminalCredentials
          setSelectedTab={setSelectedTab}
          getTerminalsListCredFanc={getTerminalsListCredFanc}
          terminalsListRes={terminalsListRes}
          terminalsCredRes={terminalsCredRes}
        />
      ),
    },
  ];
  return (
    <div>
      <div className={cls.profileTabs}>
        <span className={cls.profileBtnsSpan}>
          {profileTabItems.map((item, index) =>
            enable_login_credentials === '0' &&
            item.title === 'Terminal Credentials' ? (
              ''
            ) : (
              <Button
                className={
                  selectedTab === index
                    ? cls.activeProfileTabsBtn
                    : cls.profileTabsBtn
                }
                onClick={() => setSelectedTab(index)}
                key={index}>
                {item.title}
              </Button>
            )
          )}
        </span>
        <span>
          <a
            href={`https://smart-turn.com/app/download/SmartTurnSetup-${appVersion}.exe`}>
            <Button className={cls.winAppDownload}>Download Windows App</Button>
          </a>
        </span>
      </div>
      <div>{profileTabItems[selectedTab].content}</div>
    </div>
  );
};

import React from 'react';
import { useNotify, Loading, Button } from 'react-admin';
import { GetAppRounded } from '@material-ui/icons';
import { Filter } from '@/components/Global';
import { ParseInventoryList } from '@/actions/ParseData';
import { noScroll, removeNoScroll } from '@/actions/Helper';
import {
  getInventory,
  delInventory,
  upInventory,
  importInventory,
  exportInventory,
  updateInventoriContainers,
} from '@/actions';
import Add from './Add';
import List from './List';
import Edit from './Edit';
import styles from './styles';
import Pagination from '@/components/pagination';

export default (props) => {
  const cls = styles();
  const notify = useNotify();
  const [state, setState] = React.useState({
    edit: null,
    file: null,
  });
  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    pages: [],
  });
  const [loading, setLoading] = React.useState(true);
  const [DATA, setData] = React.useState([]);
  const [filter, setFilter] = React.useState({
    open: false,
    DATA: [],
  });

  const getInventoryList = () => {
    setLoading(true);
    getInventory({ page: pagination.currentPage })
      .then((res) => {
        let DATA = ParseInventoryList(res.data);
        let pages = [];
        for (let i = 1; i <= res.last_page; i++) {
          pages.push(i);
        }
        setPagination((prev) => {
          return {
            ...prev,
            pages,
          };
        });
        setData(DATA);
        setFilter({ open: false, DATA });
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getInventoryList();
  }, [pagination.currentPage]);

  React.useEffect(() => {
    if (state.file) {
      setLoading(true);
      importInventory(state.file)
        .then((res) => {
          let DATA = ParseInventoryList(res);
          setData(DATA);
          setFilter((f) => ({ ...f, DATA }));
          setLoading(false);
        })
        .catch((err) => {
          notify(err, 'warning');
          setData([]);
          setFilter((f) => ({ ...f, DATA: [] }));
          setLoading(false);
        });
    }
  }, [state.file, notify]);

  const Del = (id) =>
    delInventory(id)
      .then((msg) => {
        notify(msg);
        getInventory()
          .then((res) => {
            let DATA = ParseInventoryList(res.data);
            setData(DATA);
            setFilter({ ...filter, DATA });
            setLoading(false);
          })
          .catch((err) => notify(err, 'warning'));
      })
      .catch((err) => notify(err, 'warning'));

  const openPopUp = (item) => {
    noScroll();
    setState({ ...state, edit: item });
  };

  const Update = (form, inventory_id, containers) => {
    for (let el in form) {
      if (!form[el]) {
        notify('Can not add, Please check fields.', 'warning');
        return;
      }
    }

    return upInventory(form)
      .then((res) => {
        updateInventoriContainers(inventory_id, { containers }).then(() => {
          notify('Updated Successfully.');
          getInventoryList();
          setFilter({ ...filter, DATA });
          closePopup();
        });
      })
      .catch((err) => notify(err, 'warning'));
  };

  const closePopup = () => {
    removeNoScroll();
    setState({ ...state, edit: null });
  };

  const handleExport = () => {
    setLoading(true);
    exportInventory()
      .then((res) => {
        setLoading(false);
        window.open(res, '_blank');
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  return (
    <div id="inventoryEmpty" className={cls.content}>
      {state.edit && (
        <Edit data={state.edit} Close={closePopup} Update={Update} />
      )}
      <div className={cls.cardHeader}>
        <span className={cls.title}>Add Inventory</span>
        <div className={cls.row}>
          <span className={cls.title}>Import</span>
          <label className={cls.import}>
            <input
              type="file"
              style={{ display: 'none' }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => {
                setState({ ...state, file: e.target.files[0] });
                e.target.value = null;
              }}
            />
            <GetAppRounded />
            <span>XLSX</span>
          </label>
        </div>
      </div>

      <Add
        notify={notify}
        getInventoryList={getInventoryList}
        setData={(res) => {
          let DATA = ParseInventoryList(res);
          setData(DATA);
          setFilter({ ...filter, DATA });
        }}
      />
      <Filter
        title="Your Empty"
        filter={filter}
        setFilter={setFilter}
        DATA={DATA}>
        <span className={cls.title}>Export</span>
        <span className={cls.titleBlue} onClick={handleExport}>
          XLSX
        </span>
      </Filter>

      {loading ? (
        <Loading />
      ) : (
        <List
          DATA={filter.DATA}
          Del={Del}
          Edit={openPopUp}
          getInventoryList={getInventoryList}
          currentPage={pagination.currentPage}
        />
      )}
      <Pagination pagination={pagination} setPagination={setPagination} />
    </div>
  );
};

import React, { useEffect } from 'react';
import { AppBar } from '@/components/Landing';
import HomeLayout from '@/components/HomeLayout';
import styles from '../About/styles';
import $ from 'jquery';  // Import jQuery

export default () => {
  const cls = styles();


  // var myHeaders = new Headers();
  // myHeaders.append("Cookie", "ASP.NET_SessionId=b3zcjueh0dtb1ewkrqgrj3qo; BIGipServerwww.lbct.com.app~www.lbct.com_pool=3221673482.20480.0000; TS017fbeab=01ce6a95369f81522b2cfb98015121aaebe826ee8b470749a5d937e2d6f06714fd7a8f3db029388b6b5afdb18eba55a4ecf040e2e0");
  
  // var requestOptions = {
  //   method: 'GET',
  //   headers: myHeaders,
  //   redirect: 'follow'
  // };
  
  // fetch("https://www.lbct.com/Operations/EmptyRestrictions", requestOptions)
  //   .then(response => response.text())
  //   .then(result => console.log(result))
  //   .catch(error => console.log('error', error));
    
    
  //   useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const url = 'https://www.lbct.com/Operations/EmptyRestrictions';

  //       // Wait for the DOM to be ready
  //       $(document).ready(async () => {
  //         const response = await fetch(url);

  //         if (!response.ok) {
  //           throw new Error(`Failed to fetch the website. Status code: ${response.status}`);
  //         }

  //         const html = await response.text();

  //         // Use jQuery to find and log script tags with type="text/javascript"
  //         $(html).find('script[type="text/javascript"]').each(function () {
  //           try {
  //             // Log the content of each script to the console
  //             // console.log('Script content:', this.textContent);

  //             // Evaluate the script content
  //             $.globalEval(this.textContent);
  //           } catch (error) {
  //             console.error('Error executing script:', error);
  //           }
  //         });
  //       });
  //     } catch (error) {
  //       console.error('Error fetching or executing scripts:', error);
  //     }
  //   };

  //   fetchData();
  // }, []); // Empty dependency array ensures that this effect runs once on component mount


  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = 'https://www.lbct.com/Operations/EmptyRestrictions';
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`Failed to fetch the website. Status code: ${response.status}`);
        }

        const htmlContent = await response.text();
        console.log(htmlContent);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



    useEffect(() => {
      const table = document.getElementById('table');
  
      let draggingEle;
      let draggingColumnIndex;
      let placeholder;
      let list;
      let isDraggingStarted = false;
  
      let x = 0;
      let y = 0;
  
      const swap = (nodeA, nodeB) => {
        const parentA = nodeA.parentNode;
        const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;
  
        nodeB.parentNode.insertBefore(nodeA, nodeB);
        parentA.insertBefore(nodeB, siblingA);
      };
  
      const isOnLeft = (nodeA, nodeB) => {
        const rectA = nodeA.getBoundingClientRect();
        const rectB = nodeB.getBoundingClientRect();
  
        return rectA.left + rectA.width / 2 < rectB.left + rectB.width / 2;
      };
  
      const cloneTable = () => {
        const rect = table.getBoundingClientRect();
  
        list = document.createElement('div');
        list.classList.add('clone-list');
        list.style.position = 'absolute';
        list.style.left = rect.left + 'px';
        list.style.top = rect.top + 'px';
        table.parentNode.insertBefore(list, table);
  
        table.style.visibility = 'hidden';
  
        const originalCells = [].slice.call(table.querySelectorAll('tbody td'));
        const originalHeaderCells = [].slice.call(table.querySelectorAll('th'));
        const numColumns = originalHeaderCells.length;
  
        originalHeaderCells.forEach((headerCell, headerIndex) => {
          const width = parseInt(window.getComputedStyle(headerCell).width);
  
          const item = document.createElement('div');
          item.classList.add('draggable');
  
          const newTable = document.createElement('table');
          newTable.setAttribute('class', 'clone-table');
          newTable.style.width = width + 'px';
  
          const th = headerCell.cloneNode(true);
          let newRow = document.createElement('tr');
          newRow.appendChild(th);
          newTable.appendChild(newRow);
  
          const cells = originalCells.filter((c, idx) => (idx - headerIndex) % numColumns === 0);
          cells.forEach((cell) => {
            const newCell = cell.cloneNode(true);
            newCell.style.width = width + 'px';
            newRow = document.createElement('tr');
            newRow.appendChild(newCell);
            newTable.appendChild(newRow);
          });
  
          item.appendChild(newTable);
          list.appendChild(item);
        });
      };
  
      const mouseDownHandler = (e) => {
        draggingColumnIndex = [].slice.call(table.querySelectorAll('th')).indexOf(e.target);
  
        x = e.clientX - e.target.offsetLeft;
        y = e.clientY - e.target.offsetTop;
  
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      };
  
      const mouseMoveHandler = (e) => {
        if (!isDraggingStarted) {
          isDraggingStarted = true;
  
          cloneTable();
  
          draggingEle = [].slice.call(list.children)[draggingColumnIndex];
          draggingEle.classList.add('dragging');
  
          placeholder = document.createElement('div');
          placeholder.classList.add('placeholder');
          draggingEle.parentNode.insertBefore(placeholder, draggingEle.nextSibling);
          placeholder.style.width = draggingEle.offsetWidth + 'px';
        }
  
        draggingEle.style.position = 'absolute';
        draggingEle.style.top = draggingEle.offsetTop + e.clientY - y + 'px';
        draggingEle.style.left = draggingEle.offsetLeft + e.clientX - x + 'px';
  
        x = e.clientX;
        y = e.clientY;
  
        const prevEle = draggingEle.previousElementSibling;
        const nextEle = placeholder.nextElementSibling;
  
        if (prevEle && isOnLeft(draggingEle, prevEle)) {
          swap(placeholder, draggingEle);
          swap(placeholder, prevEle);
          return;
        }
  
        if (nextEle && isOnLeft(nextEle, draggingEle)) {
          swap(nextEle, placeholder);
          swap(nextEle, draggingEle);
        }
      };
  
      const mouseUpHandler = () => {
        placeholder && placeholder.parentNode.removeChild(placeholder);
  
        draggingEle.classList.remove('dragging');
        draggingEle.style.removeProperty('top');
        draggingEle.style.removeProperty('left');
        draggingEle.style.removeProperty('position');
  
        const endColumnIndex = [].slice.call(list.children).indexOf(draggingEle);
  
        isDraggingStarted = false;
  
        list.parentNode.removeChild(list);
  
        table.querySelectorAll('tr').forEach((row) => {
          const cells = [].slice.call(row.querySelectorAll('th, td'));
          draggingColumnIndex > endColumnIndex
            ? cells[endColumnIndex].parentNode.insertBefore(cells[draggingColumnIndex], cells[endColumnIndex])
            : cells[endColumnIndex].parentNode.insertBefore(cells[draggingColumnIndex], cells[endColumnIndex].nextSibling);
        });
  
        table.style.removeProperty('visibility');
  
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };
  
      table.querySelectorAll('th').forEach((headerCell) => {
        headerCell.classList.add('draggable');
        headerCell.addEventListener('mousedown', mouseDownHandler);
      });
  
      // Cleanup on component unmount
      return () => {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };
    }, []);
  

  return (
    <HomeLayout className={cls.container}>
      <div id='amirbios'>Hi Amirbios</div>

      
      <table id="table" class="table">
        <thead>
          <tr>
            <th data-type="number">No.</th>
            <th>First name</th>
            <th>Last name</th>
            <th>Date of birth</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Andrea</td>
            <td>Ross</td>
            <td>1985-12-24</td>
            <td>95945 Rodrick Crossroad</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Penelope</td>
            <td>Mills</td>
            <td>1978-8-11</td>
            <td>81328 Eleazar Fork</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Sarah</td>
            <td>Grant</td>
            <td>1981-5-9</td>
            <td>5050 Boyer Forks</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Vanessa</td>
            <td>Roberts</td>
            <td>1980-9-27</td>
            <td>765 Daryl Street</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Oliver</td>
            <td>Alsop</td>
            <td>1986-10-30</td>
            <td>11424 Ritchie Garden</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Jennifer</td>
            <td>Forsyth</td>
            <td>1983-3-13</td>
            <td>04640 Nader Ramp</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Michelle</td>
            <td>King</td>
            <td>1980-8-29</td>
            <td>272 Alysa Fall</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Steven</td>
            <td>Kelly</td>
            <td>1989-8-6</td>
            <td>5749 Foster Pike</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Julian</td>
            <td>Ferguson</td>
            <td>1981-9-17</td>
            <td>6196 Wilkinson Parkways</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Chloe</td>
            <td>Ince</td>
            <td>1983-10-28</td>
            <td>9069 Daniel Shoals</td>
          </tr>
        </tbody>
      </table>
      <AppBar />
    </HomeLayout>
  );
};

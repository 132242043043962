import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CloseRounded } from '@material-ui/icons';
import styles from './styles';
import closeIcon from '../../assets/img/closeIcon.svg';
import { Modal, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { useOutsideAlerter } from '@/hocs';
import { Tooltip, Grid, TextField, Button } from '@material-ui/core';
import clsx from 'clsx';
import { TextareaAutosize } from '@mui/material';
import Select from 'react-select';

export default ({
  Close,
  DATA,
  Label,
  terminalsList,
  open,
  handleChangeStatusShifts,
  terminal_id,
  date,
  restriction_id,
  shift_column,
  shift_value,
  shift_message,
  ssl_key,
  indexContainer,
  idx,
}) => {
  console.log('terminalsList', terminalsList);
  console.log('Label', Label);
  const selectOp = useSelector((state) => state.reducer);
  const setForm = (it, val) => setFormData({ ...form, [it]: val });
  const selectedSSlNew = selectOp.ssl.find((item) => item.label === DATA);
  const [selectedTerminal, setSelectedTerminal] = useState('All');
  const [terminalsSelectOptions, setTerminalsSelectOptions] = useState([]);

  const [selectedSsl, setSelectedSsl] = useState(selectedSSlNew);
  const [form, setFormData] = React.useState({
    size: null,
    ssl: null,
    vessel: '',
    date: new Date(),
    count: '',
    loc: null,
    containers: [],
    port: '',
    terminal: '',
    etaDate: new Date(),
    etdDate: new Date(),
    erdDate: new Date(),
    cotoffDate: new Date(),
  });
  useEffect(() => {
    if (terminalsList.length > 0) {
      const options = terminalsList.map((terminal, index) => ({
        label: terminal.name,
        slug: terminal.slug,
        value: index + 1,
      }));

      // Set portSelectOptions state
      setTerminalsSelectOptions(options);
      setSelectedTerminal(options.find((item) => item.slug === DATA));
    }
  }, [terminalsList]); // Dependencies array with portsList

  const cls = styles();
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    Close();
  });
  const [message_ssl, setMessage_ssl] = React.useState(DATA);

  const saveMessage = () => {
    const terminal_idd = terminal_id;
    const dated = date;
    const restriction_idd = restriction_id;
    const shift_columnd = shift_column;
    const shift_valued =
      shift_value === 'DUAL'
        ? 2
        : shift_value === 1 || shift_value === 0
        ? shift_value
        : null;
    const keyd = ssl_key;
    const indexContainerd = indexContainer;
    const idxd = idx;
    console.log('message_ssl', message_ssl);

    handleChangeStatusShifts(
      terminal_idd,
      dated,
      restriction_idd,
      shift_columnd,
      shift_valued,
      message_ssl,
      keyd,
      indexContainerd,
      idx
    );
    Close();
  };

  return (
    <>
      <div>
        <Modal open={open}>
          <div
            id="app_modal"
            className={cls.modal}
            // onClick={
            //   (e) => console.log('hI')
            //   // e.target.id === 'app_modal' && setShowContainersModal(false)
            // }
            style={{ width: '100%', height: '100%' }}>
            <Box className={cls.modalBox}>
              <div
                // onClick={() => setShowContainersModal(false)}
                className={cls.closeModal}>
                <img alt="close_icon" src={closeIcon} />
              </div>
              <h3 className={cls.modalTitle}>Own Chassis</h3>
              <div className={cls.containersTableContainer}>
                {/* {gatEditContainersContent()} */}
              </div>
              <button
                className={cls.updateContainersBtn}
                // onClick={() => setShowContainersModal(false)}
              >
                Close
              </button>
            </Box>
          </div>
        </Modal>
      </div>
      <div className={cls.popup}>
        <div ref={wrapperRef} className={cls.popupCard}>
          <h3>Message</h3>
          <Grid item xs={12} sm={12} lg={12}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadgePopup}>{Label}</span>
              {Label === 'Terminal' ? (
                <Select
                  className={clsx(cls.select)}
                  value={selectedTerminal}
                  onChange={(item) => {
                    setForm('terminal', item);
                    setSelectedTerminal(item);
                  }}
                  options={terminalsSelectOptions}
                />
              ) : Label === 'Vessel' ||
                Label === 'Inbound Voyage' ||
                Label === 'Outbound Voyage' ? (
                <TextField
                  type="text"
                  key={'a'}
                  value={DATA}
                  // placeholder={Label}
                  // onChange={(e) => {
                  //   setForm('vessel', e.target.value);
                  //   setVesselValue(e.target.value);
                  // }}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ min: 0 }}
                />
              ) : Label === 'Line' ? (
                <Select
                  placeholder="SSL"
                  className={clsx(cls.select)}
                  value={selectedSsl}
                  onChange={(item) => {
                    setForm('ssl', item);
                    setSelectedSsl(item);
                  }}
                  options={selectOp.ssl}
                />
              ) : Label === 'ETA' ? (
                <TextField
                  InputProps={{ disableUnderline: true }}
                  id="datetime-local"
                  type="datetime-local"
                  defaultValue={DATA}
                  className={clsx(cls.date)}
                />
              ) : (
                ''
              )}{' '}
            </div>
          </Grid>
          <span className={cls.msgPopupBtns}>
            <Button
              className={clsx(cls.saveBtn)}
              onClick={(e) => {
                saveMessage(message_ssl);
              }}>
              Save
            </Button>
            <Button
              className={clsx(cls.saveBtn)}
              // onClick={(e) => {
              //   saveMessage(message_ssl);
              // }}
            >
              Scrape
            </Button>
          </span>
        </div>
      </div>
    </>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { Icons } from '@/assets';
import { parseXML } from 'jquery';
export default makeStyles(({ breakpoints: BP }) => ({
  pageContent: {
    display: 'flex',
  },
  tuggleMenuChangeData: {
    background: '#e9e9e9',
    position: 'absolute',
    boxShadow: '0 0 10px #00000042',
    marginRight: '-105px',
    padding: '0px',
    '& > li': {
      cursor: 'pointer',

      padding: '7px 17px !important',
      '&:hover': {
        background: '#f8b100',
      },
    },
  },
  content: {
    minWidth: '96.5%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    margin: '0 20px',
    overflow: 'auto',
    transition: 'all ease 0.3s',
  },
  profileTabs: {
    background: '#ffffff',
    margin: '0 20px',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    justifyContent: 'space-between',
    borderRadius: '5px',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    '@media (max-width:763px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  profileTabsBtn: {
    border: '1px solid #e5e5e5',
    color: '#777575',
    background: '#e5e5e5',
    width: 100,
  },
  activeProfileTabsBtn: {
    background: '#f8b100',
    border: '1px solid #f8b100',
    color: '#ffffff',
    width: 100,
    '&:hover': {
      background: '#f8b100',
    },
  },
  profileBtnsSpan: {
    display: 'flex',
    gap: '10px',
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '14px 4px 5px',
    [BP.down('sm')]: {
      flexDirection: 'column',
      margin: '10px',
    },
  },
  card: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    // height: 160,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    marginBottom: 5,
  },

  list: {
    margin: '0px',
    minWidth: 500,
    width: '100%',
  },
  listDateRow: {
    marginTop: '20px',
    minWidth: 500,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [BP.down('sm')]: {
      marginTop: 15,
      marginBottom: 10,
    },
  },
  openStatus: {
    background: '#0080001f',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '8px',
    padding: '5px 11px',
    margin: '18px 0px',
    justifyContent: 'center',
    cursor: 'pointer',

    fontSize: 9,
  },
  closeStatus: {
    background: '#ff00001c',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '8px',
    padding: '5px 8px',
    margin: '18px 0px',
    justifyContent: 'center',
    cursor: 'pointer',

    fontSize: 9,
  },
  dualStatus: {
    background: '#ffa82738',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '8px',
    padding: '5px 13px',
    margin: '18px 0px',
    justifyContent: 'center',
    fontSize: 9,
    cursor: 'pointer',
  },
  msgPopupBtns: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nullStatusStatus: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '8px',
    padding: '5px 20px',
    margin: '18px 0px',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  pierSpan: {
    minHeight: '55px',
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
    ['@media (max-width:1440px)']: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  containerInput: {
    display: 'flex',
    alignItems: 'center',
    // overflow: 'hidden',
  },
  containerBadge: {
    background: '#e5e5e5',
    height: '100%',
    display: 'flex',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: '0 14px',
    whiteSpace: 'nowrap',
    width: '50px',
    minWidth: '50px',
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    border: 'solid 1px #707070',
    '& input': {
      fontFamily: 'Averta Light',
      padding: '5px 10px',
    },
  },
  saveBtn: {
    color: '#ffffff',
    background: '#44a570',
    padding: 5,
    marginTop: '10px',
    margin: '2px',
    width: '95px',
    lineHeight: '1.3rem',
    '&:hover': {
      background: '#44a570',
      color: '#ffffff',
    },
  },

  inputTextArea: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    padding: '5px',
    backgroundColor: '#fff',
    borderRadius: 5,
    width: '96%',
    border: 'solid 1px #707070',
    '& textarea': {
      fontFamily: 'Averta Light',
      padding: '5px 10px',
    },
  },
  sslTr: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    height: 'auto',
  },
  sizeSpan: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    // borderLeft: "1px solid #d2d2d2",
    boxShadow: '0 0 1px 1px #e9e9e9',
  },
  tableInfoBox: {
    background: '#d8d8d8b8',
    position: 'fixed',
    flexDirection: 'column',
    bottom: 0,
    left: 0,
    padding: 15,
    display: 'flex',
    gap: 6,
    borderTopRightRadius: 5,
    fontSize: 14,
  },
  legendItems: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    width: 20,
  },
  iconAttention: {
    marginTop: '16px',
    marginLeft: '0px',
    marginRight: '-15px',
  },

  legendItemsX: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  legendItemsXIcon: {
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-10px',
    marginRight: '-25px',
    '& > div': {
      '& > svg': {
        cursor: 'pointer',
        '& > path': {
          fill: '#000',
        },
      },
    },
    '& hover': {
      background: '#bfbfbf',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    ['@media (max-width:1440px)']: {
      marginTop: 10,
    },
  },
  row2: {
    display: 'flex',
    flexDirection: 'row',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1,
    color: '#303030',
  },
  tabsRow: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },
  titleView: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1,
    color: '#303030',
    padding: 13,
    marginLeft: 16,
  },

  titleBlue: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1,
    color: '#007fff',
    margin: '0 20px 0 10px',
    cursor: 'pointer',
  },

  import: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    cursor: 'pointer',
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 1,
    color: '#44a570',
    '& > svg': {
      height: 28,
      width: 28,
    },
  },

  printIcon: {
    height: 28,
    width: 28,
    fill: '#007fff',
    marginLeft: 10,
    cursor: 'pointer',
  },
  select: {
    width: '100%',
    fontFamily: 'Averta Light',
    '& > div': {
      // border: 'solid 1px #707070',
    },
    '& > div:first-child > div': {
      padding: '0 0 0 10px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  date: {
    boxSizing: 'border-box',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    // border: 'solid 1px #707070',
    padding: '0 10px',
    '& input': {
      width: '100%',
      padding: 0,
      cursor: 'pointer',
      fontFamily: 'Averta Light',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
  },
  searchbtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  but: {
    width: 77,
    height: 27,
    borderRadius: 5,
    backgroundColor: '#f8b100',
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    color: '#fff',
    padding: 0,
  },
  red: {
    backgroundColor: '#e4464b !important',
  },

  loc: {
    '& svg': {
      fill: '#f8b100',
    },

    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& > div': {
      border: 'solid 1px #707070',
    },
  },
  ml15: {
    marginLeft: 15,
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #707070',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
  text: {
    fontFamily: 'Averta Light',
    fontSize: 13,
    fontWeight: 300,
    lineHeight: 1.5,
    color: '#303030',
  },
  but: {
    width: 77,
    height: 27,
    borderRadius: 5,
    backgroundColor: '#f8b100',
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    color: '#fff',
    padding: 0,
  },
  red: {
    backgroundColor: '#e4464b !important',
  },
  blue: {
    backgroundColor: '#007fff !important',
    marginLeft: 10,
  },
  rowRound: {
    width: 180,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderRadius: 20,
    backgroundColor: '#fff',
    fontFamily: 'Averta Light',
    fontSize: 15,
    fontWeight: 300,
    lineHeight: 1.27,
    color: '#9f9f9f',
    marginLeft: 25,
  },
  text2: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s ease-in',
  },

  tableRow: {
    minWidth: 'fit-content',
    display: 'table-row',
    flexDirection: 'row',
    alignItems: 'center',
    height: 45,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    color: '#43424b',
    textAlign: 'center',

    [BP.up('lg')]: {
      fontSize: 14,
    },
    '& div': {
      whiteSpace: 'nowrap',
      // padding: 5,
    },
  },
  thead: {
    display: 'flex',
    fontFamily: 'Averta Light',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.2,
    color: '#43424b',
    textAlign: 'center',
    alignItems: 'center',
    [BP.up('lg')]: {
      fontSize: 15,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },
  theadSticky: {
    display: 'contents',
    fontFamily: 'Averta Bold',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.2,
    color: '#43424b',
    textAlign: 'center',
    alignItems: 'center',
    [BP.up('lg')]: {
      fontSize: 15,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },

  tbody: {
    // display: 'flex',
    // flexDirection: 'column',
    // width:'fit-content'
  },
  resText: {
    color: '#007fff',
  },
  bigText: {
    lineHeight: 2.7,
    color: '#303030',
  },
  bold: {
    fontFamily: 'Averta Bold',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 3.86,
    color: '#43424b',
  },
  editIcon: {
    height: 20,
    width: 20,
    fill: '#f8b100',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  replyIcon: {
    height: 20,
    width: 20,
    fill: '#000000',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  delIcon: {
    height: 20,
    width: 20,
    fill: '#e4464b',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  loading: {
    height: '100%',
    margin: 0,
  },
  tabActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listContainer: {
    overflow: 'auto',
    width: '100%',
    minHeight: '60vh',
    boxSizing: 'border-box',
    transition: 'all ease 0.3s',
    '&::-webkit-scrollbar-track': {
      transition: 'all 1s ease',
      background: '#bebebe',
    },
    '&::-webkit-scrollbar-thumb': {
      transition: 'all 1s ease',
      borderRadius: '3px',
      background: '#494949',
    },
    '&::-webkit-scrollbar': {
      transition: 'all 1s ease',
      width: '8px',
    },
    // '&:hover': {
    //   transition: 'all 1s ease',
    //   '&::-webkit-scrollbar-track': {
    //     background: '#bebebe',
    //   },
    //   '&::-webkit-scrollbar-thumb': {
    //     borderRadius: '3px',
    //     background: '#494949',
    //   },
    // },
  },
  checkbox: {
    '& svg': {
      fill: '#f8b100',
    },
    '& hover': {
      background: '#f8b1001a',
    },
  },
  textInput: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #707070',
    marginTop: 5,
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ShareEmail: {
    color: '#ffffff',
    background: '#007fff',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: '#ffffff',
      background: '#007fff',
    },
  },
  suggestionsContainer: {
    borderBottom: '1px solid rgb(218,220,224)',
    paddingBottom: '10px',
  },
  copyActions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    gap: '6px',
    '& button': {
      padding: 6,
    },
  },
  copySection: {
    flexDirection: 'column',
    borderRadius: '4px',
    background: '#d5d5d5',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 10px',
    '& div': {
      flex: '1',
      paddingLeft: '10px',
    },
  },
  shareLinkContainer: {
    flex: '1',
  },
  modalBox: {
    width: '400px',
    minHeight: '397px',
    background: '#FFFFFF',
    borderRadius: '5px',
    padding: '10px 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  shareLinkBox: {
    position: 'fixed',
    bottom: 15,
    left: 15,
    minHeight: '250px !important',
    zIndex: '9999',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
  },
  containersTableContainer: {
    maxHeight: '300px',
    overflow: 'auto',
    flex: '1',
  },
  closeModal: {
    textAlign: 'end',
    '& img': {
      width: '10px',
      height: '10px',
      cursor: 'pointer',
    },
  },
  modalTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '23px',
    color: '#000000',
    margin: '0 0 20px 0',
  },
  editContainerInput: {
    background: 'transparent',
    padding: '4px 2px 5px 8px',
    borderRadius: '5px',
    border: '1px solid black',
  },
  updateContainersBtnSpan: {
    display: 'flex',
    justifyContent: 'space-between',
    justifyContent: 'flex-end',
  },
  updateContainersBtnClose: {
    background: '#007fff',
    borderRadius: '5px',
    border: 'none',
    color: '#ffffff',
    fontSize: '13px',
    padding: '10px 15px',
    margin: '15px',
    display: 'block',
  },
  updateContainersBtnSave: {
    background: '#3c845d',
    borderRadius: '5px',
    border: 'none',
    color: '#ffffff',
    fontSize: '13px',
    padding: '10px 15px',
    margin: '15px',
    display: 'block',
  },
  containerTable: {
    width: '100%',
    '& tr': {
      display: 'flex',
    },
    '& th': {
      color: '#777575',
      fontSize: '13px',
      padding: '10px',
    },
    '& td': {
      padding: '0 10px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#000000',
      fontSize: '13px',
      height: '27px',
      borderBottom: '1px solid #dedede',
      '& label': {
        margin: 0,
      },
      '& svg': {
        fill: '#000000',
      },
    },
  },
  containerChecBox: {
    accentColor: '#000000',
  },
  chassisNameContainer: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: 25,
      height: 25,
      margin: '0 10px',
    },
  },

  inviteIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '8px 0',
    borderBottom: '1px solid rgb(218,220,224)',
  },
  inviteIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    fontWeight: 'bold',
    borderBottom: '2px solid #007fff',
    padding: '10px 6px',
    color: '#007fff',
  },
  email: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  removeEmail: {
    width: 28,
    height: 28,
  },
  emailInputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 5,
    borderBottom: '1px solid rgb(218,220,224)',
    paddingBottom: '8px',
    '& input': {
      flex: '1',
    },
  },
  emailBadge: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgb(218,220,224)',
    padding: '1.5px',
    borderRadius: '16px',
    gap: 6,
    width: 'fit-content',
  },
  emailAvatar: {
    width: 28,
    height: 28,
  },
  removeEmailIcon: {
    width: '1rem',
  },
  suggestion: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 0',
  },
  suggestionInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  suggestionAvatar: {
    width: 30,
    height: 30,
  },
  emailCheckBox: {
    '& svg': {
      fill: '#007fff',
    },
    '& hover': {
      background: '#007fff45',
    },
  },
  shifttd: {
    display: 'flex',
    justifyContent: 'center',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    height: 61,
    alignItems: 'center',
  },
  thdate: {
    display: 'flex',
    justifyContent: 'center',
    height: 28,
    alignItems: 'center',
  },
  open: {
    height: '-webkit-fill-available',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '8px',
    margin: '15px 0px',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    '&:hover': {
      filter: 'drop-shadow(0px 0px 6px #00000059)',
      transition: 'all ease 0.3s',
    },
  },
  close: {
    height: '-webkit-fill-available',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '8px',
    margin: '15px 0px',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    '&:hover': {
      filter: 'drop-shadow(0px 0px 6px #00000059)',
      transition: 'all ease 0.3s',
    },
  },
  dual: {
    height: '-webkit-fill-available',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '8px',
    margin: '15px 0px',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    '&:hover': {
      filter: 'drop-shadow(0px 0px 6px #00000059)',
      transition: 'all ease 0.3s',
    },
  },
  nullStatuss: {
    width: '100%',
    height: '-webkit-fill-available',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    '&:hover': {
      filter: 'drop-shadow(0px 0px 6px #00000059)',
      transition: 'all ease 0.3s',
    },
  },
  isManual: {
    background: '#f3ebd5',
    padding: '15px 0px',
    width: '78%',
    margin: '5px',
  },
  tableTdddddd: {
    background: 'yellow',
  },
  tr: {
    padding: '0px !important',
  },
  th: {
    padding: '0px !important',
  },
  td: {
    padding: '0px !important',
  },
  tableTh: {
    height: '-webkit-fill-available',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #afafaf',
    borderBottom: 'none',
    borderTop: 'none',
    justifyContent: 'center',
  },
  tableDateShowToday: {
    background: '#f8b100',
    display: 'flex',
    gap: 10,
    color: '#fff',
    flexDirection: 'row',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    backgroundPosition: 'top',
    padding: '12px 18px',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    marginTop: '-6px',
    '&:hover': {
      backgroundImage: '#ffd616',
      transition: 'all ease 0.3s',
      cursor: 'pointer',
    },
  },
  tableDateShow: {
    background: '#dfdfdf',
    display: 'flex',
    gap: 10,
    color: '#a1a1a1',
    flexDirection: 'row',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    backgroundPosition: 'center',
    padding: '8px 15px',
    border: '1px solid #cfcfcf',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    transition: 'all ease 0.3s',
    '&:hover': {
      backgroundColor: '#ffd616',
      transition: 'all ease 0.3s',
      cursor: 'pointer',
    },
  },
  terminalsNameLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  td: {
    textAlign: 'center',
    border: '1px solid red',
  },
  find: {
    width: 133,
    height: 40.3,
    borderRadius: 5,
    backgroundColor: '#f8b100',
    fontFamily: 'Averta Bold',
    fontSize: 21,
    fontWeight: 'bold',
    lineHeight: 1.86,
    color: '#303030',
    padding: 0,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ffd616',
    },
    [BP.down('xs')]: {
      fontSize: '1rem',
      width: '33vw',
      height: 30,
      '& > .MuiButton-label': {
        height: 30,
      },
    },
  },
  findIcon: {
    width: 28,
    height: 40,
    fill: '#303030',
  },
  searchbtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableRowSshName: {
    background: '#f9f9f9',
    zIndex: 1,
    position: 'relative',
  },
  shiftStatusTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 42,
    minWidth: 80,
    marginBottom: '-0px',
  },
  shiftStatus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 40,
    padding: '0px',
    minWidth: 70,
    marginBottom: '-0px',
    borderBottom: '1px solid #e9e9e9',
  },
  sslNamesTh: {
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 40,
    padding: '0px 5px',
    minWidth: 124,
    marginBottom: '-0px',
    borderBottom: '1px solid #e9e9e9',
  },
  tableTd: {
    minWidth: '80px',
    boxShadow: '0 0 1px 2px #e9e9e9',
    padding: 0,
    width: '6%',
    fontSize: '11px',
  },
  terminalLink: {
    textDecoration: 'auto',
    color: '#43424b',
    display: 'flex',
    flexDirection: 'column',
  },
  tableTdsslname: {
    margin: '-6px',
    padding: '10px',
    minWidth: '70px',
  },
  sslNameTh: {
    background: '#fff',
    border: '1px solid #d2d2d2',
  },
  shiftStatusSpan1St: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    borderRight: '1px solid #e9e9e9',
    height: 48,
    width: '-webkit-fill-available',
    alignItems: 'center',
  },
  tableTopMargin: {
    marginTop: 80,
  },
  fix: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f0f0f0',
    position: 'fixed',
    top: 70,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    overflowY: 'hidden' /* Hide vertical scrollbar */,
    overflowX: 'scroll' /* Hide horizontal scrollbar */,
    scrollbarWidth: 'none',
  },
  hideShift: {
    display: 'none',
  },
  showShift: {
    display: 'table-row !important',
  },
  fixShift: {
    background: '#f0f0f0',
    position: 'fixed',
    top: 115,
    overflowY: 'hidden' /* Hide vertical scrollbar */,
    overflowX: 'scroll' /* Hide horizontal scrollbar */,
    scrollbarWidth: 'none',
    boxShadow: '0px 4px 10px 0px #00000038',
  },

  shiftStatusSpan2Nd: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    borderLeft: '1px solid #e9e9e9',
    height: 48,
    width: '-webkit-fill-available',
    alignItems: 'center',
  },
  terminalLogo: {
    borderBottom: '1px solid #dbdbdb',
  },
  statustr: {
    background: 'red',
  },
  rrrrrrrrrrr: {
    background: 'red',
    borderBottom: '1px solid blue',
  },
  //injaaaaaaaaaaaaaaaaaaaaaaaaa

  popup: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    // zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '99999999999999999999999999999',
  },
  popupCard: {
    position: 'relative',
    padding: '15px 15px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    maxHeight: '75%',
    maxWidth: '80%',
    animation: 'zoomIn 0.7s',
    height: 220,
    width: 400,
    justifyContent: 'flex-start',
    overflow: 'auto',
  },

  pTitle: { color: '#727272' },
}));

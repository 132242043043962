import React, { useEffect, useState, useRef } from 'react';
import styles from './styles';
import {
  getInventoryShareLink,
  getPortsList,
  getTerminalsList,
  getVesselSchedule,
  getAllVesselSchedule,
  getSslVesselSchedule,
  getVesselTableTitlesList,
  setVesselTableTitlesList,
} from '@/actions';
import Pagination from '@/components/pagination';

import { useSelector } from 'react-redux';
import { Icons, TerminalsIcon } from '@/assets';
import { ReactSVG } from 'react-svg';
import { Tooltip, Grid, TextField, Button } from '@material-ui/core';
import DateInput from '@/components/DateInput';
import clsx from 'clsx';
import Select from 'react-select';
import { getGateSchedule } from '@/actions';
import { useNotify, Loading } from 'react-admin';
import { FormHelperText } from '@mui/material';

export default ({}) => {
  const cls = styles();
  const notify = useNotify();
  // const [Vessels, setVessels] = React.useState(VesselsList);
  const setForm = (it, val) => setFormData({ ...form, [it]: val });
  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    pages: [],
  });
  const [vesselsState, setVesselsState] = useState({
    loading: false,
    VesselsList: [],
  });
  const [lazyLoading, setLazyLoading] = useState(false);

  const createArrayFromNumbers = (number) => {
    let numberArray = [];
    for (let i = 1; i <= number; i++) {
      numberArray.push(i);
    }

    return numberArray;
  };

  const [form, setFormData] = React.useState({
    size: null,
    ssl: null,
    vessel: '',
    date: new Date(),
    count: '',
    loc: null,
    containers: [],
    port: '',
    terminal: '',
    etaDate: new Date(),
    etdDate: new Date(),
    erdDate: new Date(),
    cotoffDate: new Date(),
  });

  const [loading, setLoading] = React.useState(false);
  const [tableSetting, setTableSetting] = React.useState('unchangable');
  // const [tableTitlesList, setTableTitlesList] = React.useState();
  const [filterOpenStatus, setFilterOpenStatus] = useState('close');
  const [dataTypeChange, setdataTypeChange] = useState('all');
  const [priorityChange, setPriorityChange] = useState('priorityTerminal');
  const [selectedPort, setSelectedPort] = useState('All');
  const [selectedTerminal, setSelectedTerminal] = useState('All');
  const [vesselValue, setVesselValue] = useState('');
  const [portsList, setPortsList] = useState([]);
  const [terminalsList, setTerminalsList] = useState([]);
  const selectOp = useSelector((state) => state.reducer);
  const [selectedETA, setSelectedETA] = useState();
  const [selectedETD, setSelectedETD] = useState();
  const [selectedERD, setSelectedERD] = useState();
  const [selectedCotoff, setSelectedCotoff] = useState();
  const [sizeSelectOptions, setSizeSelectOptions] = useState(null);
  const [selectedSIZE, setSelectedSize] = useState(null);
  const [selectedSsl, setSelectedSsl] = useState('All');
  const [terminalOrSsl, setTerminalOrSsl] = useState('terminal');
  const [portSelectOptions, setPortSelectOptions] = useState([]);
  const [terminalsSelectOptions, setTerminalsSelectOptions] = useState([]);
  const [Vessels, setVessels] = useState([]);
  const currentPageRef = useRef(pagination.currentPage);

  const tableTitles = [
    'Terminal',
    'Vessel',
    'Inbound Voyage',
    'Outbound Voyage',
    'Line',
    'ETA',
    'ATA',
    'ETD',
    'ATD',
    'Empty Pickup',
    'ERD Refer',
    'ERD Dry',
    'Cutoff Refer',
    'Cutoff Hazard',
    'Cutoff Dry',
    'Extended Late Gate',
    'Last Update',
    'ERD Dry',
  ];
  const [tableTitlesList, setTableTitlesList] = useState([
    { name: 'Terminal', sortKey: 'terminal_name' },
    { name: 'Vessel', sortKey: 'name' },
    { name: 'Inbound Voyage', sortKey: 'voyage_in' },
    { name: 'Outbound Voyage', sortKey: 'voyage_out' },
    { name: 'Line', sortKey: 'line' },
    { name: 'ETA', sortKey: 'eta' },
    { name: 'ATA', sortKey: 'ata' },
    { name: 'ETD', sortKey: 'etd' },
    { name: 'ATD', sortKey: 'atd' },
    { name: 'Empty Pickup', sortKey: 'epd' },
    { name: 'ERD Refer', sortKey: 'erd_reefer' },
    { name: 'ERD Dry', sortKey: 'erd_dry' },
    { name: 'Cutoff Refer', sortKey: 'cutoff_reefer' },
    { name: 'Cutoff Hazard', sortKey: 'cutoff_hazard' },
    { name: 'Cutoff Dry', sortKey: 'cutoff_dry' },
    { name: 'Extended Late Gate', sortKey: 'elgd' },
    { name: 'Last Update', sortKey: 'updated_at' },
  ]);
  const tableTitlesListSsh = [
    { name: 'SSL', sortKey: 'sslName' },
    { name: 'Terminal', sortKey: 'terminal_name' },
    { name: 'Vessel', sortKey: 'name' },
    { name: 'inbound voyage', sortKey: 'voyage' },
    { name: 'Outbound voyage', sortKey: 'outbound_voyage' },
    { name: 'ETA', sortKey: 'eta' },
    { name: 'ATA', sortKey: 'ata' },
    { name: 'ETD', sortKey: 'etd' },
    { name: 'ATD', sortKey: 'atd' },
    { name: 'Empty Pickup', sortKey: 'epd' },
    { name: 'ERD Refer', sortKey: 'erd_reefer' },
    { name: 'ERD Dry', sortKey: 'erd_dry' },
    { name: 'Cutoff Refer', sortKey: 'cutoff_reefer' },
    { name: 'Cutoff Hazard', sortKey: 'cutoff_hazard' },
    { name: 'Cutoff Dry', sortKey: 'cutoff_dry' },
    { name: 'Extended Late Gate', sortKey: 'elgd' },
    { name: 'Last Update', sortKey: 'updated_at' },
  ];
  const [ddd, setddd] = useState(tableTitlesList);
  useEffect(() => {
    setddd(
      dataTypeChange == 'terminal' || dataTypeChange == 'all'
        ? tableTitlesList
        : tableTitlesListSsh
    );
  }, [dataTypeChange]);

  const req = {
    key: 'vesselTerminalTableTitles',
    data: tableTitlesList,
  };

  useEffect(() => {
    setVesselTableTitlesList(req)
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log('posterr', err);

        notify(err, 'warning');
        setLoading(false);
      });

    // console.log('updated...');
    getVesselTableTitlesList('vesselTerminalTableTitles')
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  }, [tableTitlesList]); // Empty dependency array to run only once on mount

  const [arrow, setArrow] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: '' });
  const getPortsListFanc = () => {
    setLoading(true);
    getPortsList()
      .then((res) => {
        const portsRes = Object.values(res.data.ports);
        if (portsRes) {
          setPortsList(portsRes); // Set the state directly
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const getTerminalsListFanc = () => {
    setLoading(true);
    getTerminalsList()
      .then((res) => {
        const terminalsRes = Object.values(res.data.terminals);
        if (terminalsRes) {
          setTerminalsList(terminalsRes); // Set the state directly
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const Reset = () => {
    setSelectedPort(portSelectOptions[0]);
    setSelectedTerminal(terminalsSelectOptions[0]);
    setVesselValue('');
    setSelectedSsl(selectOp.ssl[0]);
    setSelectedETA();
    setSelectedETD();
    setSelectedERD();
    setSelectedCotoff();
    setFormData({
      size: null,
      ssl: selectedSsl,
      vessel: '',
      date: new Date(),
      port: portSelectOptions,
      terminal: terminalsSelectOptions[0],
      etaDate: new Date(),
      etdDate: new Date(),
      erdDate: new Date(),
      cotoffDate: new Date(),
    });
  };

  // Use useEffect to fetch data on component mount
  useEffect(() => {
    getPortsListFanc();
    getTerminalsListFanc();
  }, []); // Empty dependency array to run only once on mount

  // Use useEffect to update portSelectOptions whenever portsList changes
  useEffect(() => {
    if (portsList.length > 0) {
      // Map portsList to create portSelectOptions
      const options = portsList.map((port, index) => ({
        label: port.name,
        slug: port.slug,
        value: index + 1,
      }));

      // Add the 'All' option at the beginning
      // options.unshift({ label: 'All', value: 0, slug: 'all' });
      // Check if any element in the selectOp.ssl list has a label of "All"
      const hasAllLabel = selectOp.ssl.some((item) => item.label === 'All');

      // If no element with label "All" is found, unshift the new record
      if (!hasAllLabel) {
        selectOp.ssl.unshift({ label: 'All', value: 0, slug: 'all' });
      }
      // Set portSelectOptions state
      setPortSelectOptions(options);
      setSelectedPort(options[0]);
      setSelectedSsl(selectOp.ssl[0]);
    }
  }, [portsList]); // Dependencies array with portsList

  useEffect(() => {
    if (terminalsList.length > 0) {
      const options = terminalsList.map((terminal, index) => ({
        label: terminal.name,
        slug: terminal.slug,
        value: index + 1,
      }));
      options.unshift({ label: 'All', value: 0, slug: 'all' });

      // Set portSelectOptions state
      setTerminalsSelectOptions(options);
      setSelectedTerminal(options[0]);
    }
  }, [terminalsList]); // Dependencies array with portsList

  const getVesselScheduleList = (
    selectedPort,
    vesselValue,
    selectedTerminal,
    selectedSsl,
    selectedETA,
    selectedETD,
    selectedERD,
    selectedCotoff
  ) => {
    const fApiPage = `page=${
      dataTypeChange === 'all' ? pagination.currentPage : 1
    }`;
    const fselectedPort =
      selectedPort != undefined &&
      selectedPort != 'All' &&
      selectedPort.value != 0
        ? `&port=${selectedPort.slug}`
        : '';
    const fvesselValue = vesselValue ? `&vessel=${vesselValue}` : '';
    const fselectedTerminal =
      selectedTerminal &&
      selectedTerminal != 'All' &&
      selectedTerminal.value != 0 &&
      selectedTerminal != undefined
        ? `&terminal=${selectedTerminal.slug}`
        : '';
    const fselectedSsl =
      selectedSsl && selectedSsl != 'All' && selectedSsl.value != 0
        ? `&line=${selectedSsl.label}`
        : '';
    const fselectedETA =
      selectedETA != undefined
        ? `&eta=${formatDate(selectedETA).fullDate}`
        : '';
    const fselectedETD =
      selectedETD != undefined
        ? `&etd=${formatDate(selectedETD).fullDate}`
        : '';
    const fselectedERD =
      selectedERD != undefined
        ? `&erd=${formatDate(selectedERD).fullDate}`
        : '';
    const fselectedCotoff = selectedCotoff
      ? `&cotoff=${formatDate(selectedCotoff).fullDate}`
      : '';

    // console.log('vesselValue', fselectedTerminal);

    const form =
      fApiPage +
      fselectedPort +
      fvesselValue +
      fselectedTerminal +
      fselectedSsl +
      fselectedETA +
      fselectedETD +
      fselectedERD +
      fselectedCotoff;
    // console.log('form11', form);

    if (dataTypeChange == 'terminal') {
      // console.log('inja');
      setLoading(true);

      getVesselSchedule(form)
        .then((res) => {
          const VesselsListtt = res.data.vessels.data;
          // setVesselsState((prev) => {
          //   // console.log('prevprev', prev.VesselsList, VesselsList);

          //   return {
          //     // ...prev,
          //     loading: true, // or false based on the condition
          //     VesselsList: [...VesselsList], // Append new vessels to the existing list
          //   };
          // });
          setVessels(VesselsListtt);

          setLoading(false);
        })
        .catch((err) => {
          notify(err, 'warning');
          setLoading(false);
        });
    } else if (dataTypeChange == 'ssl') {
      setLoading(true);

      getSslVesselSchedule(form)
        .then((res) => {
          const VesselsListt = res.data.vessels.data;
          // setVesselsState((prev) => {
          //   // console.log('prevprev', prev.VesselsList, VesselsList);

          //   return {
          //     ...prev,
          //     loading: true, // or false based on the condition
          //     VesselsList: [...VesselsList], // Append new vessels to the existing list
          //   };
          // });

          setVessels(VesselsListt);

          setLoading(false);
        })
        .catch((err) => {
          notify(err, 'warning');
          setLoading(false);
        });
    } else if (dataTypeChange == 'all') {
      if (!pagination.pages) {
        setLoading(true);
      }
      getAllVesselSchedule(form)
        .then((res) => {
          const VesselsList = res.data.vessels.data;
          // setVessels((prev) => {
          //   return {
          //     ...prev,
          //     loading: true,
          //     VesselsList: VesselsList,
          //   };
          // });
          // setVessels(VesselsList);
          // When setting the vessels data:
          setVesselsState((prev) => {
            // console.log('prevprev', prev.VesselsList, VesselsList);

            return {
              ...prev,
              loading: true, // or false based on the condition
              VesselsList: [...prev.VesselsList, ...VesselsList], // Append new vessels to the existing list
            };
          });

          // console.log('pagination.currentPage', pagination.currentPage);
          // setVesselsState((prev) => {
          //   const newList = VesselsList.filter(
          //     (vessel) =>
          //       !prev.VesselsList.some(
          //         (prevVessel) => prevVessel.id === vessel.id
          //       )
          //   );

          //   return {
          //     ...prev,
          //     lazyLoading: true, // or false based on the condition
          //     VesselsList: [...prev.VesselsList, ...newList], // Append new vessels to the existing list if they are not already there
          //   };
          // });

          setLoading(false);
        })
        .catch((err) => {
          notify(err, 'warning');
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    // Initial load
    getVesselScheduleList(
      selectedPort,
      vesselValue,
      selectedTerminal,
      selectedSsl,
      selectedETA,
      selectedETD,
      selectedERD,
      selectedCotoff,
      pagination.currentPage
    );
  }, [
    dataTypeChange,
    selectedPort,
    vesselValue,
    selectedTerminal,
    selectedSsl,
    selectedETA,
    selectedETD,
    selectedERD,
    selectedCotoff,
    lazyLoading,
    currentPageRef.current,
  ]);
  // console.log('vesselsState', vesselsState);

  useEffect(() => {
    const handleScrollLoadData = () => {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;
      if (bottom && !loading) {
        // Increment the page using the ref
        currentPageRef.current += 1;
        setLazyLoading(true);
        // Update the pagination state
        setPagination((prev) => ({
          ...prev,
          currentPage: currentPageRef.current,
        }));
      }
    };

    window.addEventListener('scroll', handleScrollLoadData);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScrollLoadData);
    };
  }, [pagination.currentPage]);

  // useEffect(() => {}, [VesselsList]);
  function getCurrentWeekDates() {
    const today = new Date();

    // Adjust for Monday-based weeks: getDay() returns 0 for Sunday, 1 for Monday, etc.
    const currentDay = (today.getDay() + 6) % 7; // Adjust so Monday is 0 and Sunday is 6

    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(today.getDate() - currentDay);

    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      dates.push(date);
    }
    return dates;
  }

  // Format the date to separate day, abbreviated month, and year
  function formatDate(date, format) {
    const day = date.getDate().toString().padStart(2, '0');
    const dayNumber = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dayName = dayNames[date.getDay()];
    const monthNumber = String(monthIndex + 1).padStart(2, '0'); // Add leading zero if necessary
    const month = monthNames[monthIndex];
    const fullDate = `${year}/${monthNumber}/${day}`;
    if (format == 1) {
      return `${year}-${monthNumber}-${dayNumber}`;
    } else {
      return { fullDate, day, dayNumber, dayName, monthNumber, month, year };
    }
  }

  const weekDates = getCurrentWeekDates();
  const formattedDates = weekDates.map((date) => formatDate(date));

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = { month: mm, day: dd, year: yyyy };

  const todayIndex = formattedDates.findIndex(
    (date) =>
      date.day === parseInt(today.day) &&
      date.monthNumber === parseInt(today.month) &&
      date.year === today.year
  );

  // const originalDate = "2024-04-20T04:31:52.000000Z";
  const formatUpdatedAtDate = (dateString) => {
    function isInvalidDate(dateStr) {
      return isNaN(Date.parse(dateStr));
    }

    if (dateString == 'please contact your line') {
      return dateString;
    } else if (dateString == 'TBA') {
      return dateString;
    } else if (isInvalidDate(dateString) == true) {
      return '';
    } else {
      const date = new Date(dateString);
      const formattedDate =
        new Date(date).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit',
        }) +
        ' ' +
        new Date(date).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
      // console.log(dateString);
      return formattedDate;
    }
  };

  const amirbiosRef = useRef(null);
  const tableRef = useRef(null);
  const amirbios1Ref = useRef(null);
  const amirbios2Ref = useRef(null);

  const [tableWidth, setTableWidth] = useState(0);
  const [spanWidth, setSpanWidth] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (amirbiosRef.current && amirbios1Ref.current) {
        const amirbiosTop = amirbiosRef.current.getBoundingClientRect().top;

        if (amirbiosTop <= 69) {
          amirbios2Ref.current.classList.add(cls.fix);
          tableRef.current.classList.add(cls.tableTopMargin);
        } else {
          amirbios2Ref.current.classList.remove(cls.fix);
          tableRef.current.classList.remove(cls.tableTopMargin);
        }
      }
      syncScroll('amirbios');
    };
    const updateWidth = () => {
      if (tableRef.current && amirbios1Ref.current) {
        const tableCurrentWidth =
          tableRef.current.getBoundingClientRect().width;
        setTableWidth(tableCurrentWidth);
      }
    };

    const updateHeadWidth = () => {
      if (amirbiosRef.current && amirbios2Ref.current) {
        const tableeCurrentWidth =
          amirbiosRef.current.getBoundingClientRect().width;
        setSpanWidth(tableeCurrentWidth);
      }
    };

    const syncScroll = (source) => {
      if (amirbiosRef.current || amirbios2Ref.current) {
        if (source === 'amirbios') {
          amirbios2Ref.current.scrollLeft = amirbiosRef.current.scrollLeft;
        } else if (source === 'amirbios2') {
          amirbiosRef.current.scrollLeft = amirbios2Ref.current.scrollLeft;
        }
      }
    };

    updateWidth();
    updateHeadWidth();

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateWidth);
    window.addEventListener('resize', updateHeadWidth);
    window.addEventListener('scroll', updateWidth);
    window.addEventListener('scroll', updateHeadWidth);
    if (amirbiosRef.current || amirbios2Ref.current) {
      amirbiosRef.current.addEventListener('scroll', () => {
        syncScroll('amirbios');
      });
      amirbios2Ref.current.addEventListener('scroll', () => {
        syncScroll('amirbios2');
      });
    }

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateWidth);
      window.removeEventListener('resize', updateHeadWidth);
      window.removeEventListener('scroll', updateWidth);
      window.removeEventListener('scroll', updateHeadWidth);
      if (amirbiosRef.current || amirbios2Ref.current) {
        amirbiosRef.current.removeEventListener('scroll', () =>
          syncScroll('amirbios')
        );
        amirbios2Ref.current.removeEventListener('scroll', () =>
          syncScroll('amirbios2')
        );
      }
    };
  }, [loading, vesselsState, Vessels, dataTypeChange, currentPageRef.current]);

  useEffect(() => {
    if (amirbios2Ref.current) {
      amirbios2Ref.current.style.width = `${spanWidth - 4}px`;
    }
  }, [spanWidth]);

  useEffect(() => {
    if (amirbios1Ref.current) {
      amirbios1Ref.current.style.width = `${tableWidth - 4}px`;
    }
  }, [tableWidth]);

  const VesselsEnd = Vessels?.map((vessel) => ({
    ...vessel,
    terminal_name: vessel.terminal?.slug, // Assuming the terminal name is nested under `terminal.name`
    sslName: vessel.shipping_line?.slug, // Assuming the terminal name is nested under `terminal.name`
    line: vessel.additional?.ssl,
  }));

  const VesselsEndState = vesselsState.VesselsList?.map((vessel) => ({
    ...vessel,
    terminal_name: vessel.terminal?.slug, // Assuming the terminal name is nested under `terminal.name`
    sslName: vessel.shipping_line?.slug, // Assuming the terminal name is nested under `terminal.name`
    line: vessel.additional?.ssl,
  }));

  const sortBy = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
      setArrow(key + 'Down');
    } else {
      setArrow(key + 'Top');
    }
    const sortedVessels = [...VesselsEnd].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
    setVessels(sortedVessels);
    const sortedVesselsState = [...VesselsEndState].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
    setVesselsState((prev) => {
      return {
        loading: true, // or false based on the condition
        VesselsList: [...sortedVesselsState], // Append new vessels to the existing list
      };
    });

    setSortConfig({ key, direction });
  };

  useEffect(() => {
    const table = document.getElementById('table');
    if (!table) {
      console.error('Table element not found');
      return;
    }

    let draggingEle;
    let draggingColumnIndex;
    let placeholder;
    let list;
    let isDraggingStarted = false;

    let x = 0;
    let y = 0;

    const swap = (nodeA, nodeB) => {
      const parentA = nodeA.parentNode;
      const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

      nodeB.parentNode.insertBefore(nodeA, nodeB);
      parentA.insertBefore(nodeB, siblingA);
    };

    const isOnLeft = (nodeA, nodeB) => {
      const rectA = nodeA.getBoundingClientRect();
      const rectB = nodeB.getBoundingClientRect();

      return rectA.left + rectA.width / 2 < rectB.left + rectB.width / 2;
    };

    const cloneTable = () => {
      const rect = table.getBoundingClientRect();

      list = document.createElement('div');
      list.classList.add('clone-list');
      list.style.position = 'absolute';
      list.style.left = `${rect.left}px`;
      list.style.top = `${rect.top}px`;
      table.parentNode.insertBefore(list, table);

      table.style.visibility = 'hidden';

      const originalCells = Array.from(table.querySelectorAll('tbody td'));
      const originalHeaderCells = Array.from(table.querySelectorAll('th'));
      const numColumns = originalHeaderCells.length;
      originalHeaderCells.forEach((headerCell, headerIndex) => {
        const width = parseInt(window.getComputedStyle(headerCell).width);

        const item = document.createElement('div');
        item.classList.add(cls.draggable);

        const newTable = document.createElement('table');
        newTable.setAttribute('class', 'clone-table');
        newTable.style.width = `${width}px`;

        const th = headerCell.cloneNode(true);
        let newRow = document.createElement('tr');
        newRow.appendChild(th);
        newTable.appendChild(newRow);

        const cells = originalCells.filter(
          (c, idx) => (idx - headerIndex) % numColumns === 0
        );
        cells.forEach((cell) => {
          const newCell = cell.cloneNode(true);
          newCell.style.width = `${width}px`;
          newRow = document.createElement('tr');
          newRow.appendChild(newCell);
          newTable.appendChild(newRow);
        });

        item.appendChild(newTable);
        list.appendChild(item);
      });
    };

    const mouseDownHandler = (e) => {
      const headerCells = Array.from(table.querySelectorAll('th'));
      draggingColumnIndex = headerCells.indexOf(e.target);

      // console.log('Clicked Header:', e.target.textContent); // Check which header is clicked
      // console.log('Dragging Column Index:', draggingColumnIndex);

      x = e.clientX - e.target.offsetLeft;
      y = e.clientY - e.target.offsetTop;

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = (e) => {
      if (!isDraggingStarted) {
        isDraggingStarted = true;

        cloneTable();

        draggingEle = Array.from(list.children)[draggingColumnIndex];
        // console.log('list.children', list.children);
        // console.log('draggingColumnIndex', draggingColumnIndex);
        draggingEle.classList.add(cls.dragging);

        placeholder = document.createElement('div');
        placeholder.classList.add(cls.placeholder);
        draggingEle.parentNode.insertBefore(
          placeholder,
          draggingEle.nextSibling
        );
        placeholder.style.width = `${draggingEle.offsetWidth}px`;
      }

      draggingEle.style.position = 'absolute';
      draggingEle.style.top = `${draggingEle.offsetTop + e.clientY - y}px`;
      draggingEle.style.left = `${draggingEle.offsetLeft + e.clientX - x}px`;

      x = e.clientX;
      y = e.clientY;

      const prevEle = draggingEle.previousElementSibling;
      const nextEle = placeholder.nextElementSibling;

      if (prevEle && isOnLeft(draggingEle, prevEle)) {
        swap(placeholder, draggingEle);
        swap(placeholder, prevEle);
        return;
      }

      if (nextEle && isOnLeft(nextEle, draggingEle)) {
        swap(nextEle, placeholder);
        swap(nextEle, draggingEle);
      }
    };

    const mouseUpHandler = () => {
      if (isDraggingStarted) {
        placeholder && placeholder.parentNode.removeChild(placeholder);

        draggingEle.classList.remove(cls.dragging);
        draggingEle.style.removeProperty('top');
        draggingEle.style.removeProperty('left');
        draggingEle.style.removeProperty('position');

        const endColumnIndex = Array.from(list.children).indexOf(draggingEle);

        list.parentNode.removeChild(list);

        table.querySelectorAll('tr').forEach((row) => {
          const cells = Array.from(row.querySelectorAll('th, td'));
          draggingColumnIndex > endColumnIndex
            ? cells[endColumnIndex].parentNode.insertBefore(
                cells[draggingColumnIndex],
                cells[endColumnIndex]
              )
            : cells[endColumnIndex].parentNode.insertBefore(
                cells[draggingColumnIndex],
                cells[endColumnIndex].nextSibling
              );
        });

        table.style.removeProperty('visibility');

        // Get the current order of headers
        const headers = Array.from(table.querySelectorAll('th')).map((th) =>
          th.textContent.trim()
        );

        // Create a new ordered list based on the current headers
        const newTableTitlesList = headers.map((name) => {
          return (
            tableTitlesList.find((item) => item.name === name) || {
              name,
              sortKey: '',
            }
          );
        });

        // Log the updated header objects as JSON

        // Update the state if necessary
        setTableTitlesList(newTableTitlesList);
      }

      isDraggingStarted = false;

      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    table.querySelectorAll('th').forEach((headerCell) => {
      headerCell.classList.add(cls.draggable);
      headerCell.addEventListener('mousedown', mouseDownHandler);
    });

    // Cleanup on component unmount
    return () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };
  }, []);

  const mappedData = vesselsState.VesselsList?.map((item) => {
    const result = {};

    // Process each key in the item object
    Object.keys(item).forEach((key) => {
      if (key.startsWith('diff_')) {
        const baseKey = key.replace('diff_', '');
        const terminalKey = key.replace('terminal_', '');
        const sslKey = key.replace('ssl_', '');
        if (item[key] === false) {
          result[`render_${baseKey}`] = item[`show_${baseKey}`];
        } else if (item[key] === true) {
          if (item[`terminal_${baseKey}`]) {
            // console.log('truuuuuuee', baseKey, item[`terminal_${baseKey}`]);
            result[`render_${baseKey}`] = item[`terminal_${baseKey}`];
          } else if (item[`ssl_${baseKey}`]) {
            result[`render_${baseKey}`] = item[`ssl_${baseKey}`];
          }
        }
      }
    });

    // Define render_terminal_info
    const terminalSlug = item.show_terminal?.slug;
    const terminalLink = item.show_terminal?.links.vessel_schedule;
    const sslSlug = item.show_shipping_line?.slug;
    const sslLink = item.show_shipping_line?.links.vessel;
    const vesselName = item.show_name
      ? item.show_name
      : item.ssl_name
      ? item.ssl_name
      : item.terminal_name
      ? item.terminal_name
      : '';
    // const ata = item.terminal_ata ? item.terminal_ata : item.ssl_ata;
    const atd = item.terminal_atd ? item.terminal_atd : item.ssl_atd;
    const erdDry = item.terminal_erd_dry
      ? item.terminal_erd_dry
      : item.ssl_erd_dry;
    const erdReefer = item.terminal_erd_dry
      ? item.terminal_erd_dry
      : item.ssl_erd_reefer;
    const updated_at = item.terminal_updated_at;

    // Add render_terminal_info to the result if terminalSlug exists
    if (terminalSlug) {
      return {
        ...result,
        render_terminal_slug: terminalSlug,
        render_terminal_Link: terminalLink,
        render_ssl_slug: sslSlug,
        render_ssl_link: sslLink,
        render_vessel_name: vesselName,
        // render_ata: ata,
        render_atd: atd,
        render_erd_dry: erdDry,
        render_erd_reefer: erdReefer,
        render_Updated_at: updated_at,
      };
    }

    return result;
  });
  // console.log('mappedData', mappedData);

  // console.log(mappedData);

  // useEffect(() => {
  //   console.log('im prioritize');
  // }, [priorityChange]);

  // console.log("ddd", VesselsEnd);

  return (
    <>
      <div id="emptyCard" className={cls.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`Ports `}</span>
              <Select
                placeholder="Ports"
                className={clsx(cls.select)}
                value={selectedPort}
                onChange={(item) => {
                  setForm('port', item);
                  setSelectedPort(item); // Set selected Port state
                }}
                options={portSelectOptions}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`Vessel `}</span>
              <TextField
                type="text"
                key={'a'}
                value={vesselValue}
                placeholder={`Vessel Name`}
                onChange={(e) => {
                  setForm('vessel', e.target.value);
                  setVesselValue(e.target.value);
                }}
                InputProps={{ disableUnderline: true }}
                inputProps={{ min: 0 }}
              />
            </div>
          </Grid>
          {/* <Grid className={cls.hidsection} item xs={0} sm={0} lg={3}></Grid> */}
          <Grid className={cls.terminalLargView} item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`Terminal`}</span>
              <Select
                placeholder="Terminal"
                className={clsx(cls.select)}
                value={selectedTerminal}
                onChange={(item) => {
                  setForm('terminal', item);
                  setSelectedTerminal(item);
                }}
                options={terminalsSelectOptions}
              />
            </div>
          </Grid>

          <Grid className={cls.buttonsDiv} item xs={12} sm={4} lg={3}>
            <div className={cls.containerActions}>
              <div className={cls.containerRowRound}>
                <span
                  onClick={() => setdataTypeChange('all')}
                  className={clsx(
                    cls.chassisText,
                    dataTypeChange == 'all' && cls.activeChassis
                  )}>
                  All
                </span>
                <span
                  onClick={() => setdataTypeChange('terminal')}
                  className={clsx(
                    cls.chassisText,
                    dataTypeChange == 'terminal' && cls.activeChassis
                  )}>
                  Terminal
                </span>
                <span
                  onClick={() => setdataTypeChange('ssl')}
                  className={clsx(
                    cls.chassisText,
                    dataTypeChange == 'ssl' && cls.activeChassis
                  )}>
                  Shipping Line
                </span>
              </div>
            </div>
          </Grid>
          {/* {dataTypeChange == 'all' ? (
            <Grid className={cls.buttonsDiv} item xs={12} sm={4} lg={3}>
              <div className={cls.containerActions}>
                Priority
                <div className={cls.containerRowRound}>
                  <span
                    onClick={() => setPriorityChange('priorityTerminal')}
                    className={clsx(
                      cls.chassisText,
                      priorityChange == 'priorityTerminal' && cls.activeChassis
                    )}>
                    Terminal
                  </span>
                  <span
                    onClick={() => setPriorityChange('prioritySsl')}
                    className={clsx(
                      cls.chassisText,
                      priorityChange == 'prioritySsl' && cls.activeChassis
                    )}>
                    SSL
                  </span>
                </div>
              </div>
            </Grid>
          ) : (
            ''
          )} */}
        </Grid>
        <Grid
          id="hiddenFiltersDiv"
          className={cls.hiddenFiltersDiv}
          container
          spacing={2}>
          <Grid className={cls.hidsection} item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`Terminal `}</span>
              <Select
                placeholder="Terminal"
                className={clsx(cls.select)}
                value={selectedTerminal}
                onChange={(item) => {
                  setForm('terminal', item);
                  setSelectedTerminal(item);
                }}
                options={terminalsSelectOptions}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`SSL `}</span>
              <Select
                placeholder="SSL"
                className={clsx(cls.select)}
                value={selectedSsl}
                onChange={(item) => {
                  setForm('ssl', item);
                  setSelectedSsl(item);
                }}
                options={selectOp.ssl}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`ETA `}</span>
              <DateInput
                placeholder="ETA"
                className={clsx(cls.date)}
                format="MM/dd/yyyy"
                value={form.etaDate}
                onChange={(item) => {
                  setForm('etaDate', item);
                  setSelectedETA(item);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`ETD `}</span>
              <DateInput
                placeholder="ETD"
                className={clsx(cls.date)}
                format="MM/dd/yyyy"
                value={form.etdDate}
                onChange={(item) => {
                  setForm('etdDate', item);
                  setSelectedETD(item);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`ERD `}</span>
              <DateInput
                placeholder="ERD"
                className={clsx(cls.date)}
                format="MM/dd/yyyy"
                value={form.erdDate}
                onChange={(item) => {
                  setForm('erdDate', item);
                  setSelectedERD(item);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`Cutoff `}</span>
              <DateInput
                placeholder="Cotoff"
                className={clsx(cls.date)}
                format="MM/dd/yyyy"
                value={form.cotoffDate}
                onChange={(item) => {
                  setForm('cotoffDate', item);
                  setSelectedCotoff(item);
                }}
              />
            </div>
          </Grid>
        </Grid>
        <div className={cls.actions}>
          <Grid className={cls.moreLink} container spacing={2}>
            <Grid className={cls.filtersfooter} item xs={12} sm={12} lg={12}>
              <Button className={clsx(cls.but, cls.red)} onClick={Reset}>
                Reset
              </Button>
              {filterOpenStatus == 'open' ? (
                <span
                  onClick={() => {
                    setFilterOpenStatus('close');
                    document.getElementById('hiddenFiltersDiv').style.display =
                      'none';
                  }}
                  className={cls.arrowSpan}>
                  Less Filters
                  <ReactSVG src={Icons.arrowTop} className={cls.arrowTop} />
                </span>
              ) : filterOpenStatus == 'close' ? (
                <span
                  onClick={() => {
                    setFilterOpenStatus('open');
                    document.getElementById('hiddenFiltersDiv').style.display =
                      'flex';
                  }}
                  className={cls.arrowSpan}>
                  More Filters
                  <ReactSVG src={Icons.arrowDown} className={cls.arrowDown} />
                </span>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      {dataTypeChange == 'terminal' || dataTypeChange == 'ssl' ? (
        <div ref={amirbiosRef} id="amirbios" className={cls.listContainer}>
          <table ref={tableRef} id="table" className={cls.list}>
            <thead ref={amirbios2Ref} id="amirbios1">
              <tr ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
                {ddd?.map((item, i) => (
                  <th
                    key={i}
                    onClick={() => sortBy(item.sortKey)}
                    style={{ flex: 0.2 }}
                    className={
                      arrow == item.sortKey + 'Top' ||
                      arrow == item.sortKey + 'Down'
                        ? cls.tableTitleSortingThis
                        : cls.tableTitleSorting
                    }>
                    {item.name}
                    {arrow == item.sortKey + 'Top' ? (
                      <span className={cls.arrowSpan}>
                        <ReactSVG
                          src={Icons.arrowTop}
                          className={cls.arrowTop}
                        />
                      </span>
                    ) : arrow == item.sortKey + 'Down' ? (
                      <span className={cls.arrowSpan}>
                        <ReactSVG
                          src={Icons.arrowDown}
                          className={cls.arrowDown}
                        />
                      </span>
                    ) : (
                      ''
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            {loading ? (
              <Loading />
            ) : (
              <tbody>
                {Object.values(VesselsEnd).map((idx, i) => (
                  <tr className={cls.tableRow} key={idx.id}>
                    {dataTypeChange == 'ssl' ? (
                      <td style={{ flex: 0.2 }} className={cls.shifttd}>
                        <span style={{ flex: 1 }}>
                          <a
                            className={cls.terminalLink}
                            target="_blank"
                            href={idx.shipping_line?.links?.vessel}>
                            {idx.shipping_line?.slug}
                          </a>
                        </span>
                      </td>
                    ) : (
                      ''
                    )}
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span
                        style={{ flex: 1 }}
                        className={cls.terminalsNameLogo}>
                        <a
                          className={cls.terminalLink}
                          target="_blank"
                          href={idx.terminal?.links?.vessel_schedule}>
                          <img
                            width={50}
                            src={
                              idx.terminal_name == 'APM'
                                ? TerminalsIcon.APM
                                : idx.terminal_name == 'FMS'
                                ? TerminalsIcon.FMS
                                : idx.terminal_name == 'EVERPORT'
                                ? TerminalsIcon.EVERPORT
                                : idx.terminal_name == 'LACT'
                                ? TerminalsIcon.LACT
                                : idx.terminal_name == 'PCT'
                                ? TerminalsIcon.PCT
                                : idx.terminal_name == 'PST'
                                ? TerminalsIcon.PST
                                : idx.terminal_name == 'Trapac'
                                ? TerminalsIcon.Trapac
                                : idx.terminal_name == 'WBCT'
                                ? TerminalsIcon.WBCT
                                : idx.terminal_name == 'WCC'
                                ? TerminalsIcon.WCC
                                : idx.terminal_name == 'YTI'
                                ? TerminalsIcon.YTI
                                : idx.terminal_name == 'TTI'
                                ? TerminalsIcon.TTI
                                : idx.terminal_name == 'ITS'
                                ? TerminalsIcon.ITS
                                : idx.terminal_name == 'LBCT'
                                ? TerminalsIcon.LBCT
                                : idx.terminal_name == 'MATSON'
                                ? TerminalsIcon.MATSON
                                : idx.terminal_name == 'SSA MARINE'
                                ? TerminalsIcon.SSA
                                : idx.terminal_name == 'SSA STL'
                                ? TerminalsIcon.STL
                                : idx.terminal_name == 'PTS'
                                ? TerminalsIcon.PTS
                                : ''
                            }
                          />
                          {idx.terminal_name}
                        </a>
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>{idx.name}</span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>{idx.voyage_in}</span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>{idx.voyage_out}</span>
                    </td>
                    {dataTypeChange == 'terminal' || dataTypeChange == 'all' ? (
                      <td style={{ flex: 0.2 }} className={cls.shifttd}>
                        <span style={{ flex: 1 }}>{idx.additional?.ssl}</span>
                      </td>
                    ) : (
                      ''
                    )}
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.eta ? formatUpdatedAtDate(`${idx.eta}`) : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.ata ? formatUpdatedAtDate(`${idx.ata}`) : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.etd ? formatUpdatedAtDate(`${idx.etd}`) : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.atd ? formatUpdatedAtDate(`${idx.atd}`) : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {' '}
                        {idx.epd ? formatUpdatedAtDate(`${idx.epd}`) : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.etd_reefer
                          ? formatUpdatedAtDate(`${idx.etd_reefer}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.etd_dry
                          ? formatUpdatedAtDate(`${idx.etd_dry}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.cutoff_reefer
                          ? formatUpdatedAtDate(`${idx.cutoff_reefer}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.cutoff_hazatd
                          ? formatUpdatedAtDate(`${idx.cutoff_hazatd}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.cutoff_dry
                          ? formatUpdatedAtDate(`${idx.cutoff_dry}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.exlada ? formatUpdatedAtDate(`${idx.elgd}`) : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.updated_at
                          ? formatUpdatedAtDate(`${idx.updated_at}`)
                          : ''}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      ) : dataTypeChange == 'all' ? (
        <div ref={amirbiosRef} id="amirbios" className={cls.listContainer}>
          <table ref={tableRef} id="table" className={cls.list}>
            <thead ref={amirbios2Ref} id="amirbios1">
              <tr ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
                {ddd?.map((item, i) => (
                  <th
                    key={i}
                    onClick={() => sortBy(item.sortKey)}
                    style={{ flex: 0.2 }}
                    className={
                      arrow == item.sortKey + 'Top' ||
                      arrow == item.sortKey + 'Down'
                        ? cls.tableTitleSortingThis
                        : cls.tableTitleSorting
                    }>
                    {item.name}
                    {arrow == item.sortKey + 'Top' ? (
                      <span className={cls.arrowSpan}>
                        <ReactSVG
                          src={Icons.arrowTop}
                          className={cls.arrowTop}
                        />
                      </span>
                    ) : arrow == item.sortKey + 'Down' ? (
                      <span className={cls.arrowSpan}>
                        <ReactSVG
                          src={Icons.arrowDown}
                          className={cls.arrowDown}
                        />
                      </span>
                    ) : (
                      ''
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            {loading ? (
              <Loading />
            ) : (
              <tbody>
                {Object.values(mappedData).map((idx, i) => (
                  <tr className={cls.tableRow} key={idx.id}>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span
                        style={{ flex: 1 }}
                        className={cls.terminalsNameLogo}>
                        <a
                          className={cls.terminalLink}
                          target="_blank"
                          href={idx.render_terminal_Link}>
                          <img
                            width={50}
                            src={
                              idx.render_terminal_slug == 'APM'
                                ? TerminalsIcon.APM
                                : idx.render_terminal_slug == 'FMS'
                                ? TerminalsIcon.FMS
                                : idx.render_terminal_slug == 'EVERPORT'
                                ? TerminalsIcon.EVERPORT
                                : idx.render_terminal_slug == 'LACT'
                                ? TerminalsIcon.LACT
                                : idx.render_terminal_slug == 'PCT'
                                ? TerminalsIcon.PCT
                                : idx.render_terminal_slug == 'PST'
                                ? TerminalsIcon.PST
                                : idx.render_terminal_slug == 'Trapac'
                                ? TerminalsIcon.Trapac
                                : idx.render_terminal_slug == 'WBCT'
                                ? TerminalsIcon.WBCT
                                : idx.render_terminal_slug == 'WCC'
                                ? TerminalsIcon.WCC
                                : idx.render_terminal_slug == 'YTI'
                                ? TerminalsIcon.YTI
                                : idx.render_terminal_slug == 'TTI'
                                ? TerminalsIcon.TTI
                                : idx.render_terminal_slug == 'ITS'
                                ? TerminalsIcon.ITS
                                : idx.render_terminal_slug == 'LBCT'
                                ? TerminalsIcon.LBCT
                                : idx.render_terminal_slug == 'MATSON'
                                ? TerminalsIcon.MATSON
                                : idx.render_terminal_slug == 'SSA MARINE'
                                ? TerminalsIcon.SSA
                                : idx.render_terminal_slug == 'SSA STL'
                                ? TerminalsIcon.STL
                                : idx.render_terminal_slug == 'PTS'
                                ? TerminalsIcon.PTS
                                : ''
                            }
                          />
                          {idx.render_terminal_slug}
                        </a>
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_vessel_name ? idx.render_vessel_name : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_voyage_in ? idx.render_voyage_in : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_voyage_out ? idx.render_voyage_out : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_ssl_slug ? idx.render_ssl_slug : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_eta
                          ? formatUpdatedAtDate(`${idx.render_eta}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_ata
                          ? formatUpdatedAtDate(`${idx.render_ata}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_etd
                          ? formatUpdatedAtDate(`${idx.render_etd}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_atd
                          ? formatUpdatedAtDate(`${idx.render_atd}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {' '}
                        {idx.render_epd
                          ? formatUpdatedAtDate(`${idx.render_epd}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_erd_reefer
                          ? formatUpdatedAtDate(`${idx.render_erd_reefer}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_erd_dry
                          ? formatUpdatedAtDate(`${idx.render_erd_dry}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_cutoff_reefer
                          ? formatUpdatedAtDate(`${idx.render_cutoff_reefer}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_cutoff_hazard
                          ? formatUpdatedAtDate(`${idx.render_cutoff_hazard}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_cutoff_dry
                          ? formatUpdatedAtDate(`${idx.render_cutoff_dry}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_elgd
                          ? formatUpdatedAtDate(`${idx.render_elgd}`)
                          : ''}
                      </span>
                    </td>
                    <td style={{ flex: 0.2 }} className={cls.shifttd}>
                      <span style={{ flex: 1 }}>
                        {idx.render_Updated_at
                          ? formatUpdatedAtDate(`${idx.render_Updated_at}`)
                          : ''}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      ) : (
        ''
      )}
      {lazyLoading && <span className={cls.lazyLoadingSpan}>Loading...</span>}

      <Pagination pagination={pagination} setPagination={setPagination} />
    </>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import zIndex from '@mui/material/styles/zIndex';

export default makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 25,
    justifyContent: 'center',
  },
  terminalcontent: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flex3: {
    display: 'flex',
    flex: 3,
  },
  flex4: {
    display: 'flex',
    justifyContent: 'center',
    flex: 4,
  },
  title: {
    fontSize: 25,
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    color: '#303030',
    marginBottom: 25,
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: 850,
  },
  btn: {
    background: '#f8b100',
    color: '#ffffff',
    '&:hover': {
      background: '#f8b100',
      color: '#ffffff',
    },
  },
  profileTabs: {
    background: '#ffffff',
    margin: '0 30px',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    justifyContent: 'space-between',
    borderRadius: '10px',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    marginTop: 10,
    '@media (max-width:763px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  profileTabsBtn: {
    border: '1px solid #e5e5e5',
    color: '#777575',
    background: '#e5e5e5',
    minWidth: 100,
    width: 160,
    '@media (max-width:600px)': {
      width: 200,
    },
  },
  activeProfileTabsBtn: {
    background: '#f8b100',
    border: '1px solid #f8b100',
    color: '#ffffff',
    minWidth: 100,
    width: 160,
    '@media (max-width:600px)': {
      width: 200,
    },
    '&:hover': {
      background: '#f8b100',
    },
  },
  profileBtnsSpan: {
    display: 'flex',
    gap: '10px',
    '@media (max-width:600px)': {
      margin: '20px 20px 0px',
      flexDirection: 'column',
    },
  },
  winAppDownload: {
    color: '#000000',
    width: '200px',
    background: '#f8b100',
    '@media (max-width:600px)': {
      margin: '0px 0px 20px',
    },
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
  referInputContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    margin: '25px 0',
    '@media (max-width:500px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  terminalCInputContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 19,
    margin: '6px 0',
    justifyContent: 'flex-end',
    '@media (max-width:500px)': {
      gap: 10,
    },
  },
  inputLabel: {
    fontWeight: 'bold',
    display: 'block',
    width: 100,
    fontSize: 15,
  },
  terminalLink: {
    textDecoration: 'unset',
    color: '#000',
    '&:hover': {
      color: '#6b6b6b',
    },
  },
  hiddenIcons: {
    marginRight: '-8px',
    visibility: 'hidden',
  },
  intoInputIcons: {
    marginRight: '-12px',
    zIndex: '1',
  },
  statusInputIcons: {
    marginRight: '-16px',
    zIndex: '1',
  },

  referBtn: {
    width: '100%',
    background: '#0CA549',
    color: '#ffffff',
    '&:hover': {
      background: '#0CA549',
      color: '#ffffff',
    },
  },
  referBtn2: {
    marginTop: 60,
    width: '100%',
    background: '#007FFF',
    color: '#ffffff',
    '&:hover': {
      background: '#007FFF',
      color: '#ffffff',
    },
  },
  saveTerminalCredBtn: {
    marginTop: 20,
    width: '20%',
    background: '#0CA549',
    color: '#ffffff',
    '&:hover': {
      background: '#0CA549',
      color: '#ffffff',
    },
  },

  referListTitle: {
    fontWeight: 'bold',
    fontFamily: 'Averta bold',
    paddingBottom: 5,
    display: 'block',
  },
  referListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  referListItemTitle: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  referListBigText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  referListSmallText: {
    fontSize: 12,
    color: 'gray',
  },
  referListSmallBoldText: {
    fontSize: 12,
    color: '#000000',
    fontWeight: 'bold',
    fontFamily: 'Averta bold',
  },
  referListBottomBorder: {
    borderBottom: '1.5px solid #80808042',
    padding: '10px 12px',
  },
  referInfoBottomBorder: {
    borderBottom: '1.5px solid #80808042',
    padding: '12px 0',
  },
  card1: {
    background: '#ffffff',
    flex: '1',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: '5px',
    padding: '15px 20px',
    height: 268,
    marginTop: 20,
  },
  card2: {
    background: '#ffffff',
    flex: '1',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: '5px',
    padding: '15px 20px',
  },
  referContainer: {
    margin: '20px 60px',
    '@media (max-width:600px)': {
      margin: '20px',
    },
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    paddingLeft: 70,
    height: '100%',
    maxWidth: 450,
    width: '100%',
    '@media (max-width:960px)': {
      paddingLeft: 0,
      maxWidth: '100%',
    },
  },
  referCard: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 10,
    background: '#f9c03180',
    padding: '15px 20px',
    marginBottom: 20,
    // '& h2': {
    //   fontFamily: 'Averta bold',
    //   marginTop: 0,
    // },
    // '& p': {
    //   fontFamily: 'Averta bold',
    //   marginBottom: 0,
    //   paddingBottom: 6,
    //   lineHeight: 2,
    // },
  },
  terminalMsgCard: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 10,
    background: '#f9c03180',
    padding: '1px 20px',
    maxWidth: '645px',
    width: '100%',
    marginBottom: 6,
    '@media (max-width:600px)': {
      width: 'fit-content',
    },
  },
  userPassInputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    '@media (min-width:600px)': {
      flexDirection: 'row',
      gap: 16,
    },
  },
  inputsSpan: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '17px',
    '@media (min-width:600px)': {
      flexDirection: 'row',
    },
  },
  actionsSpan: {
    '@media (max-width:600px)': {
      display: 'none',
    },
    '@media (min-width:600px)': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '16px',
    },
  },
  titleMobileSpan: {
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width:600px)': {
      flexDirection: 'row',
    },
  },
  actionsMobileSpan: {
    '@media (min-width:600px)': {
      display: 'none',
    },

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
}));

import React, { useEffect, useState } from 'react';
import {
  Tooltip,
  IconButton,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {
  DeleteRounded,
  CreateRounded,
  Share as ShareIcon,
} from '@material-ui/icons';
import { fDate, fTimeDate } from '@/actions/Helper';
import styles from './styles';
import Containers from './Containers';
import Share from './Share/index';
import {
  disableInventoryContainer,
  enableInventoryContainer,
  getInventoryShareLink,
  getTerminalsList,
} from '@/actions';
import { useNotify } from 'react-admin';
import { useSelector } from 'react-redux';
export default ({ DATA = [], Del, Edit, getInventoryList, currentPage }) => {
  const cls = styles();
  const [showContainersModal, setShowContainersModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [randomTerminalName, setRandomTerminalName] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [shareLink, setShareLink] = useState({
    loading: null,
    DATA: null,
  });
  const [terminalsList, setTerminalsList] = useState([]);

  const fakeData = [
    {
      GatedOut: '8-24-2024',
      GatedIn: '',
      ReturnApt: '8-26-2024',
      ReturnLocation: 'APM',
      DualTerminal: 'APM',
      LFD: '8-27-2024',
      EstPerDiem: '$360',
      DisputableDays: '1',
      DisputableAmount: '$120',
    },
    {
      GatedOut: '8-24-2024',
      GatedIn: '',
      ReturnApt: 'No Apt. Available',
      ReturnLocation: 'TTI / YTI',
      DualTerminal: 'TTI',
      LFD: '8-27-2024',
      EstPerDiem: '$1,360',
      DisputableDays: '3',
      DisputableAmount: '$900',
    },
    {
      GatedOut: '8-21-2024',
      GatedIn: '8-24-2024',
      ReturnApt: '8-24-2024',
      ReturnLocation: 'FMS',
      DualTerminal: '',
      LFD: '8-24-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },
    {
      GatedOut: '8-22-2024',
      GatedIn: '',
      ReturnApt: '8-25-2024',
      ReturnLocation: 'FMS',
      DualTerminal: '',
      LFD: '8-25-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },
    {
      GatedOut: '8-29-2024',
      GatedIn: '',
      ReturnApt: '',
      ReturnLocation: 'WBCT / ITS / TTI',
      DualTerminal: 'WBCT',
      LFD: '9-02-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },
    {
      GatedOut: '9-02-2024',
      GatedIn: '',
      ReturnApt: '',
      ReturnLocation: 'Everport',
      DualTerminal: 'Everport',
      LFD: '9-06-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },
    {
      GatedOut: '8-23-2024',
      GatedIn: '',
      ReturnApt: '9-3-2024',
      ReturnLocation: 'PCT',
      DualTerminal: '',
      LFD: '8-26-2024',
      EstPerDiem: '$2,400',
      DisputableDays: '3',
      DisputableAmount: '$930',
    },
    {
      GatedOut: '8-25-2024',
      GatedIn: '8-28-2024',
      ReturnApt: '8-28-2024',
      ReturnLocation: 'YTI',
      DualTerminal: 'YTI',
      LFD: '8-28-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },

    {
      GatedOut: '8-25-2024',
      GatedIn: '8-28-2024',
      ReturnApt: '8-28-2024',
      ReturnLocation: 'YTI',
      DualTerminal: 'YTI',
      LFD: '8-28-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },
    {
      GatedOut: '8-29-2024',
      GatedIn: '',
      ReturnApt: 'No Apt. Available',
      ReturnLocation: 'TTI / YTI',
      DualTerminal: 'TTI',
      LFD: '9-02-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },

    {
      GatedOut: '9-01-2024',
      GatedIn: '',
      ReturnApt: 'No Apt. Available',
      ReturnLocation: 'TTI / YTI / PCT /FMS',
      DualTerminal: 'TTI/FMS',
      LFD: '9-04-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },
    {
      GatedOut: '8-26-2024',
      GatedIn: '',
      ReturnApt: 'No Apt. Available',
      ReturnLocation: 'WBCT / LBCT / PCT',
      DualTerminal: 'WBCT',
      LFD: '8-29-2024',
      EstPerDiem: '$1,450',
      DisputableDays: '2',
      DisputableAmount: '$620',
    },

    {
      GatedOut: '9-03-2024',
      GatedIn: '',
      ReturnApt: 'No Apt. Available',
      ReturnLocation: 'LBCT / PCT',
      DualTerminal: 'PCT',
      LFD: '9-06-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },

    {
      GatedOut: '8-29-2024',
      GatedIn: '',
      ReturnApt: '9-04-2024',
      ReturnLocation: 'LBCT / PCT',
      DualTerminal: 'PCT',
      LFD: '9-06-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },

    {
      GatedOut: '8-27-2024',
      GatedIn: '',
      ReturnApt: '8-30-2024',
      ReturnLocation: 'WBCT',
      DualTerminal: '',
      LFD: '8-30-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },

    {
      GatedOut: '9-03-2024',
      GatedIn: '',
      ReturnApt: 'No Apt. Available',
      ReturnLocation: 'Trapac / WBCT',
      DualTerminal: 'Trapac',
      LFD: '9-07-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },

    {
      GatedOut: '8-21-2024',
      GatedIn: '',
      ReturnApt: 'No Apt. Available',
      ReturnLocation: 'LBCT/ Trapac / WBCT',
      DualTerminal: 'LBCT',
      LFD: '8-24-2024',
      EstPerDiem: '$3,200',
      DisputableDays: '7',
      DisputableAmount: '$2,200',
    },
    {
      GatedOut: '9-03-2024',
      GatedIn: '',
      ReturnApt: '9-05-2024',
      ReturnLocation: 'WBCT',
      DualTerminal: 'WBCT',
      LFD: '9-06-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },

    {
      GatedOut: '8-26-2024',
      GatedIn: '',
      ReturnApt: '9-06-2024',
      ReturnLocation: 'LBCT',
      DualTerminal: '',
      LFD: '8-29-2024',
      EstPerDiem: '$1,620',
      DisputableDays: '2',
      DisputableAmount: '$340',
    },

    {
      GatedOut: '9-03-2024',
      GatedIn: '',
      ReturnApt: 'No Apt. Available',
      ReturnLocation: 'LBCT / WBCT / ITS',
      DualTerminal: 'ITS',
      LFD: '9-07-2024',
      EstPerDiem: '',
      DisputableDays: '',
      DisputableAmount: '',
    },
  ];

  console.log('fakeData', fakeData);

  const notify = useNotify();
  const enable_empty_tracking = useSelector(
    (state) => state.reducer.user?.metas?.enable_empty_tracking.value
  );
  const mptyTrackingEnable = enable_empty_tracking;

  console.log('enable_empty_tracking', enable_empty_tracking);
  const getTerminalsListFanc = () => {
    getTerminalsList()
      .then((res) => {
        const terminalsRes = Object.values(res.data.terminals);
        if (terminalsRes) {
          setTerminalsList(terminalsRes); // Set the state directly
        }
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };
  // useEffect(() => {
  //   getTerminalsListFanc();
  //   console.log('terminalsList', terminalsList);
  // }, [terminalsList]);
  // const updateContainers = (index, name, value) => {
  //   setContainers((prev) => {
  //     return [
  //       ...prev.slice(0, index),
  //       {
  //         ...prev[index],
  //         [name]: value,
  //       },
  //       ...prev.slice(index + 1),
  //     ];
  //   });
  // };
  // const setRefreshInventories = (false) => {

  // }

  const handleEnableCongtainer = (containerId, index) => {
    enableInventoryContainer(containerId).then((response) => {
      if (response.success) {
        notify('container updated');
        // updateContainers(index, 'status', 1);
        // setRefreshInventories(true);
        getInventoryList();
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleDisableContainer = (containerId, index) => {
    disableInventoryContainer(containerId).then((response) => {
      if (response.success) {
        notify('container updated');
        // updateContainers(index, 'status', 0);
        // setRefreshInventories(true);
        getInventoryList();
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleChangeContainerStatus = (value, containerId, index) => {
    if (value === true) {
      handleEnableCongtainer(containerId, index);
    } else {
      handleDisableContainer(containerId, index);
    }
  };

  const terminalsListt = [
    { slug: 'APM' },
    { slug: 'FMS' },
    { slug: 'Suggerstion EVERPORT/FMS' },
    { slug: 'PCT' },
    { slug: '' },
    { slug: 'Suggerstion Trapac/PCT' },
    { slug: 'WBCT' },
    { slug: 'Suggerstion WBCT/TTI' },
    { slug: 'YTI' },
    { slug: '' },
    { slug: 'ITS' },
    { slug: 'LBCT' },
    { slug: 'Suggerstion ITS/PCT' },
    { slug: 'MATSON' },
    { slug: 'SSA MARINE' },
    { slug: '' },
    { slug: 'TTI' },
    { slug: 'SSA STL' },
    { slug: 'PTS' },
  ];

  const getRandomTerminalName = () => {
    const randomIndex = Math.floor(Math.random() * terminalsListt.length);
    return terminalsListt[randomIndex].slug;
  };
  const getRandomFakeData = () => {
    const randomIndex = Math.floor(Math.random() * fakeData.length);
    return fakeData[randomIndex];
  };
  React.useEffect(() => {
    console.log('getRandomFakeData', getRandomFakeData());
  }, [mptyTrackingEnable]);

  const getActivContainers = (inventory, target) => {
    if (inventory) {
      let activeContainers = [];
      let deactiveContainers = [];
      inventory.containers.forEach((container) => {
        if (container.status === 1) {
          activeContainers.push(container);
        } else if (container.status === 0) {
          deactiveContainers.push(container);
        }
      });

      if (deactiveContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`all ${cls.containerChecBox}`}
            checked={true}
          />
        );
      } else if (activeContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`any ${cls.containerChecBox}`}
            checked={false}
          />
        );
      } else {
        return (
          <input
            type="checkbox"
            checked={true}
            className={`some ${cls.containerChecBox}`}
          />
        );
      }
    }
  };

  const handleGetShareLink = (i) => {
    setShareLink({ loading: i.id });
    getInventoryShareLink(i.id)
      .then((res) => {
        setShareModal(i);
        setShareLink({
          loading: null,
          DATA: res,
        });
      })
      .catch((err) => {
        setShareLink({ loading: null });
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    let someCompletedCheckboxs = document.getElementsByClassName('some');
    let allCompletedCheckboxs = document.getElementsByClassName('all');
    let notCompletedCheckboxs = document.getElementsByClassName('any');
    for (let i = 0; i < someCompletedCheckboxs.length; i++) {
      someCompletedCheckboxs[i].indeterminate = true;
    }
    for (let i = 0; i < allCompletedCheckboxs.length; i++) {
      allCompletedCheckboxs[i].indeterminate = false;
    }
    for (let i = 0; i < notCompletedCheckboxs.length; i++) {
      notCompletedCheckboxs[i].indeterminate = false;
      notCompletedCheckboxs[i].checked = false;
    }
  }, [getActivContainers, getInventoryList]);
  // console.log('data', DATA);
  let uniqueIndex = 0;

  return (
    <div id="amirbios" className={cls.listContainer}>
      {showContainersModal && selectedRow && (
        <Containers
          open={showContainersModal}
          DATA={DATA}
          selectedRow={selectedRow}
          setShowContainersModal={setShowContainersModal}
          getInventoryList={getInventoryList}
        />
      )}
      {shareModal && !shareLink.loading && (
        <Share
          shareLink={shareLink.DATA}
          shareModal={shareModal}
          setShareModal={setShareModal}
        />
      )}
      {mptyTrackingEnable === '1' ? (
        <table className={cls.list}>
          <thead className={cls.thead}>
            <th style={{ flex: 0.4 }}>#</th>
            <th style={{ flex: 0.6 }}>Size</th>
            <th style={{ flex: 0.7 }}>SSL</th>
            <th style={{ flex: 0.7 }}>Container #</th>
            <th style={{ flex: 0.9 }}>Chassis #</th>
            <th style={{ flex: 0.8 }}>Gated Out</th>
            <th style={{ flex: 0.8 }}>Gated In</th>
            <th style={{ flex: 0.7 }}>Empty Location</th>
            <th style={{ flex: 0.8 }}>Return Apt</th>
            <th style={{ flex: 0.7 }}>Return Location</th>
            <th style={{ flex: 0.7 }}>Dual Terminal</th>
            <th style={{ flex: 0.7 }}>LFD</th>
            <th style={{ flex: 0.7 }}>Est. PerDiem</th>
            <th style={{ flex: 0.7 }}>Disputable Days</th>
            <th style={{ flex: 0.7 }}>Disputable Amount</th>
            <th style={{ flex: 0.6 }}>Chassis Type</th>
            <th style={{ flex: 0.9 }}>Available Date</th>
            <th style={{ flex: 0.9 }}>Last Update</th>
            <th style={{ flex: 1.3 }}>Action</th>
          </thead>
          <tbody className={cls.tbody}>
            {DATA.map((i, idx) =>
              i.containers.map((container, idd) => {
                // افزایش شمارنده یکتا برای هر ردیف
                uniqueIndex++;
                const fakeData = getRandomFakeData();
                return (
                  <tr className={cls.tableRow} key={uniqueIndex}>
                    <td style={{ flex: 0.4 }}>
                      {uniqueIndex + (currentPage - 1) * 10}
                    </td>
                    <td style={{ flex: 0.6 }}>{i.size}</td>
                    <td style={{ flex: 0.7 }}>{i.ssl}</td>
                    <td style={{ flex: 0.7 }}>
                      {container.name ? container.name : 'N/A'}
                    </td>
                    <td style={{ flex: 0.9 }}>
                      {container.chases ? container.chases : 'N/A'}
                    </td>
                    <td style={{ flex: 0.8 }}>
                      {fakeData.GatedOut ? fakeData.GatedOut : '-'}
                    </td>
                    <td style={{ flex: 0.8 }}>
                      {fakeData.GatedIn ? fakeData.GatedIn : '-'}
                    </td>
                    <td style={{ flex: 0.7 }}>{i.loc}</td>
                    <td style={{ flex: 0.8 }}>
                      {fakeData.ReturnApt
                        ? fakeData.ReturnApt
                        : 'No Apt. Available'}
                    </td>
                    <td style={{ flex: 0.7 }}>
                      {fakeData.ReturnLocation ? fakeData.ReturnLocation : '-'}
                    </td>
                    <td style={{ flex: 0.7 }}>
                      {fakeData.DualTerminal ? fakeData.DualTerminal : '-'}
                    </td>
                    <td style={{ flex: 0.7 }}>
                      {fakeData.LFD ? fakeData.LFD : '-'}
                    </td>
                    <td style={{ flex: 0.7 }}>
                      {fakeData.EstPerDiem ? fakeData.EstPerDiem : '-'}
                    </td>
                    <td style={{ flex: 0.7 }}>
                      {fakeData.DisputableDays ? fakeData.DisputableDays : '-'}
                    </td>
                    <td style={{ flex: 0.7 }}>
                      {fakeData.DisputableAmount
                        ? fakeData.DisputableAmount
                        : '-'}
                    </td>
                    <td style={{ flex: 0.6 }}>
                      {container.own_chases === 0 ? 'Pool' : 'Own'}
                    </td>
                    <td style={{ flex: 0.9 }}>{fTimeDate(i.date)}</td>
                    <td style={{ flex: 0.9 }}>{fTimeDate(i.up)}</td>
                    <td className={cls.tabActions} style={{ flex: 1.3 }}>
                      {shareLink.loading === i.id ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Tooltip arrow title="Share" placement="top">
                          <IconButton
                            aria-label="share"
                            disabled={i.active === 0}
                            onClick={() => handleGetShareLink(i)}>
                            <ShareIcon
                              style={
                                i.active === 0
                                  ? { fill: '#a2a2a2' }
                                  : { fill: '#000000' }
                              }
                              className={cls.replyIcon}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip arrow title="Edit" placement="top">
                        <IconButton aria-label="edit" onClick={() => Edit(i)}>
                          <CreateRounded className={cls.editIcon} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title="Containers" placement="top">
                        <Checkbox
                          checked={container.status === 1}
                          className={cls.checkbox}
                          onChange={(e) =>
                            handleChangeContainerStatus(
                              e.target.checked,
                              container.id,
                              idd
                            )
                          }
                        />
                      </Tooltip>
                      <Tooltip arrow title="Delete" placement="top">
                        <IconButton
                          aria-label="delete"
                          onClick={() => Del(i.id)}>
                          <DeleteRounded className={cls.delIcon} />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      ) : (
        <table className={cls.list}>
          <thead className={cls.thead}>
            <th style={{ flex: 0.35 }}>#</th>
            <th style={{ flex: 1 }}>Size</th>
            <th style={{ flex: 0.7 }}>SSL</th>
            <th style={{ flex: 0.7 }}>Active</th>
            <th style={{ flex: 0.7 }}>Container #</th>
            <th style={{ flex: 1.2 }}>Location</th>
            <th style={{ flex: 1 }}>Available Date</th>
            <th style={{ flex: 0.7 }}>Number of Own Chassis</th>
            <th style={{ flex: 0.7 }}>Number of Pool Chassis</th>
            <th style={{ flex: 1 }}>Last Update</th>
            <th style={{ flex: 1 }}>Action</th>
          </thead>
          <tbody className={cls.tbody}>
            {DATA.map((i, idx) => (
              <tr className={cls.tableRow} key={idx}>
                <td style={{ flex: 0.35 }}>
                  {idx + 1 + (currentPage - 1) * 10}
                </td>
                <td style={{ flex: 1 }}>{i.size}</td>
                <td style={{ flex: 0.7 }}>{i.ssl}</td>
                <td style={{ flex: 0.7 }}>{i.count_all}</td>
                <td style={{ flex: 0.7 }}>{i.active}</td>
                <td style={{ flex: 1.2 }}>{i.loc}</td>
                <td style={{ flex: 1 }}>{fDate(i.date)}</td>
                <td style={{ flex: 0.7 }}>{i.own_chases_count}</td>
                <td style={{ flex: 0.7 }}>{i.pool_chases_count}</td>
                <td style={{ flex: 1 }}>{fTimeDate(i.up)}</td>
                <td className={cls.tabActions} style={{ flex: 1 }}>
                  {shareLink.loading === i.id ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Tooltip
                      className={cls.tooltipClass}
                      arrow
                      title="Share"
                      placement="top">
                      <IconButton
                        aria-label="share"
                        disabled={i.active === 0}
                        onClick={() => handleGetShareLink(i)}>
                        <ShareIcon
                          style={
                            i.active === 0
                              ? { fill: '#a2a2a2' }
                              : { fill: '#000000' }
                          }
                          className={cls.replyIcon}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip arrow title="Edit" placement="top">
                    <IconButton aria-label="edit" onClick={() => Edit(i)}>
                      <CreateRounded className={cls.editIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow title="Containers" placement="top">
                    <IconButton
                      onClick={() => {
                        setShowContainersModal(!showContainersModal);
                        setSelectedRow(i);
                      }}>
                      {/* <img src={getActivContainers(i)} /> */}
                      {getActivContainers(i)}
                    </IconButton>
                  </Tooltip>

                  <Tooltip arrow title="Delete" placement="top">
                    <IconButton aria-label="delete" onClick={() => Del(i.id)}>
                      <DeleteRounded className={cls.delIcon} />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

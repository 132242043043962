import React, { useEffect, useState } from 'react';
import styles from './styles';
import { Grid, TextField, Button } from '@material-ui/core';
import { Loading, useNotify } from 'react-admin';
import {
  getReferredList,
  postTerminalCredsFunc,
  sendReferralInvite,
} from '@/actions';
import Pagination from '@/components/pagination';
import { withStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { Icons } from '@/assets';
import { ReactSVG } from 'react-svg';

const CustomSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#0CA549',
    },
    '&$checked + $track': {
      backgroundColor: '#52d869',
    },
  },
  checked: {},
  track: {},
})(Switch);

export default ({
  setSelectedTab,
  terminalsListRes,
  terminalsCredRes,
  getTerminalsListCredFanc,
}) => {
  const [formData, setFormData] = useState([]);
  const [apiResData, setapiResData] = useState([]);
  const [referred, setReferred] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pages: [1],
  });
  const cls = styles();
  const notify = useNotify();

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  // ابتدا داده‌ها را مرتب کنیم
  const sortedTerminalsCredRes = terminalsCredRes
    .map((credential) => ({
      relation_id: Number(credential.relation_id),
      relation_user: credential.relation_user,
      relation_pass: credential.relation_pass,
      enable:
        credential.enable === '1'
          ? true
          : credential.enable === '0'
          ? false
          : '',
      checked: credential.checked,
    }))
    .sort((a, b) => a.relation_id - b.relation_id);

  // ایجاد آرایه خالی با طول مناسب
  const maxRelationId = Math.max(
    ...sortedTerminalsCredRes.map((item) => item.relation_id)
  );
  const indexedTerminalsCredRes = Array.from({ length: maxRelationId });

  // پر کردن آرایه با داده‌ها در ایندکس مناسب
  sortedTerminalsCredRes.forEach((credential) => {
    indexedTerminalsCredRes[credential.relation_id - 1] = credential;
  });

  // فیلتر کردن آرایه برای حذف ایندکس‌های خالی
  const filteredTerminalsCredRes = indexedTerminalsCredRes.filter(
    (item) => item !== undefined
  );

  // حالا می‌توانید از `filteredTerminalsCredRes` استفاده کنید
  // console.log('sortedTerminalsCredRes', sortedTerminalsCredRes);
  // console.log('filteredTerminalsCredRes', filteredTerminalsCredRes);

  useEffect(() => {
    setFormData(indexedTerminalsCredRes);
    // console.log('apiResData', apiResData);
  }, []);

  // console.log('terminalsListRes', terminalsListRes);
  // console.log('terminalsCredRes', terminalsCredRes);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const changeFormData = (key, value, index) => {
    setFormData((prev) => {
      const updatedFormData = [...prev];

      // If the entry at the given index is null or undefined, initialize it
      if (!updatedFormData[index]) {
        updatedFormData[index] = {
          relation_id: index + 1, // Use index + 1 for ID if IDs start from 1
          relation_user: '',
          relation_pass: '',
          enable: true,
        };
      }

      // Update the specific field in the existing entry
      updatedFormData[index][key] = value;

      return updatedFormData;
    });
  };

  const createJsonPayload = (formData) => {
    // Filter out null entries and map to the desired format
    const userCredentials = formData.filter(
      (item) => item !== null && item !== undefined
    );

    return {
      user_credentials: userCredentials,
    };
  };
  // console.log('formdate', formData);

  const handleSendInvite = () => {
    // console.log('formdate', createJsonPayload(formData));

    postTerminalCredsFunc(createJsonPayload(formData))
      .then((res) => {
        if (!res.errors) {
          // console.log('ressseee', res.data.user_credentials);
          const apiRes = res.data.user_credentials.sort(
            (a, b) => a.relation_id - b.relation_id
          );

          // ایجاد آرایه خالی با طول مناسب
          const maxRelationId = Math.max(
            ...apiRes.map((item) => item.relation_id)
          );
          const indexedTerminalsCredRes = Array.from({
            length: maxRelationId + 1,
          });

          // پر کردن آرایه با داده‌ها در ایندکس مناسب
          apiRes.forEach((credential) => {
            indexedTerminalsCredRes[credential.relation_id - 1] = credential;
          });
          getTerminalsListCredFanc();
          // setFormData(indexedTerminalsCredRes);
          // console.log('setFormData', indexedTerminalsCredRes);
          notify('Updated successful', 'success');
        } else {
          Object.keys(res.errors).map((key) => {
            notify(res.errors[key][0], 'warning');
          });
        }
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const createArrayFromNumbers = (number) => {
    let numberArray = [];
    for (let i = 1; i <= number; i++) {
      numberArray.push(i);
    }

    return numberArray;
  };

  const getReferred = () => {
    getReferredList({ page: pagination.currentPage })
      .then((res) => {
        if (res.message === 'Complete your profile please.') {
          notify('Complete your profile please.', 'warning');
          setSelectedTab(0);
        } else {
          setReferred(res.data);
          setPagination((prev) => {
            return {
              ...prev,
              pages: createArrayFromNumbers(
                Math.ceil(
                  res.data.referred_users.total /
                    res.data.referred_users.per_page
                )
              ),
            };
          });
        }
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };
  const findNameById = (relationId) => {
    const item = terminalsListRes.find(
      (listItem) => listItem.id === parseInt(relationId, 10)
    );
    return item ? item.name : 'Unknown';
  };

  useEffect(() => {
    getReferred();
  }, [pagination.currentPage]);

  return referred ? (
    <div className={cls.referContainer}>
      <Grid className={cls.terminalcontent}>
        <div className={cls.terminalMsgCard}>
          <div
          // dangerouslySetInnerHTML={{
          //   __html: referred.current_referral_program.content1,
          // }}
          />

          {/* <h2>Spread the word and earn rewards</h2> */}
          <p>
            Please enter your information to log into the terminals websites
          </p>
        </div>
        <Grid className={cls.pageContent} item xs={12} md={12}>
          <div>
            {terminalsListRes.map((item, index) => {
              return (
                <div className={cls.terminalCInputContainer} key={index}>
                  <span className={cls.titleMobileSpan}>
                    <a
                      className={cls.terminalLink}
                      target="_blank"
                      href={item.links.vessel_schedule}>
                      <span className={cls.inputLabel}> {item.slug}</span>
                    </a>
                    <span className={cls.actionsMobileSpan}>
                      <ReactSVG
                        src={
                          formData[index]?.checked === 'CHECKED'
                            ? Icons.checkIcon
                            : formData[index]?.checked === 'REJECTED'
                            ? Icons.rejectIcon
                            : Icons.pendingIcon
                        }
                        className={
                          formData[index]?.relation_user &&
                          formData[index]?.relation_pass
                            ? cls.statusInputIcons
                            : cls.hiddenIcons
                        }
                      />

                      <CustomSwitch
                        className={
                          formData[index]?.relation_user &&
                          formData[index]?.relation_pass
                            ? cls.Icons
                            : cls.hiddenIcons
                        }
                        checked={formData[index]?.enable}
                        onChange={(ev) =>
                          changeFormData('enable', ev.target.checked, index)
                        }
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </span>
                  </span>
                  <span className={cls.userPassInputs}>
                    <span className={cls.inputsSpan}>
                      <ReactSVG
                        src={Icons.userProfileIcon}
                        className={cls.intoInputIcons}
                      />
                      <TextField
                        type={item.type}
                        placeholder={`Username/Email`}
                        className={cls.input}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ min: 1 }}
                        value={formData[index]?.relation_user || ''}
                        onChange={(ev) =>
                          changeFormData(
                            'relation_user',
                            ev.target.value,
                            index
                          )
                        }
                      />
                    </span>
                    <span className={cls.inputsSpan}>
                      <ReactSVG
                        src={Icons.passwordIcon}
                        className={cls.intoInputIcons}
                      />
                      <TextField
                        type={item.type}
                        placeholder="Password"
                        className={cls.input}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ min: 1 }}
                        value={formData[index]?.relation_pass || ''}
                        onChange={(ev) =>
                          changeFormData(
                            'relation_pass',
                            ev.target.value,
                            index
                          )
                        }
                      />
                    </span>
                  </span>
                  <span className={cls.actionsSpan}>
                    <ReactSVG
                      src={
                        formData[index]?.checked === 'CHECKED'
                          ? Icons.checkIcon
                          : formData[index]?.checked === 'REJECTED'
                          ? Icons.rejectIcon
                          : Icons.pendingIcon
                      }
                      className={
                        formData[index]?.relation_user &&
                        formData[index]?.relation_pass
                          ? cls.statusInputIcons
                          : cls.hiddenIcons
                      }
                    />

                    <CustomSwitch
                      className={
                        formData[index]?.relation_user &&
                        formData[index]?.relation_pass
                          ? cls.Icons
                          : cls.hiddenIcons
                      }
                      checked={formData[index]?.enable}
                      onChange={(ev) =>
                        changeFormData('enable', ev.target.checked, index)
                      }
                      name="checkedA"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </span>
                </div>
              );
            })}
            <div className={cls.terminalCInputContainer}>
              <span />
              <Button
                onClick={() => handleSendInvite()}
                className={cls.saveTerminalCredBtn}>
                Save
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  ) : (
    <Loading />
  );
};
